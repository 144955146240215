import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ResetWrapper = styled(Typography)(({ theme }) => ({
  color: theme.palette.general.lightGrey2,
  backgroundColor: theme.palette.common.white,
  cursor: 'pointer',
  padding: theme.spacing(0.5, 3),
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: '20px',

  '&:hover': {
    backgroundColor: theme.palette.background.secondary,
  },

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0.5, 1.5),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.5),
  },
}));
