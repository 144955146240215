import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import CustomButton from 'components/CustomButton';

export const DoneButton = styled(CustomButton)(() => ({
  width: '250px',
  height: '60px',

  '&:disabled': {
    height: '60px',
  },
})) as typeof CustomButton;

export const OrderNumber = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5),
  border: `1px solid ${theme.palette.border.main}`,
  marginBottom: theme.spacing(6),
  borderRadius: '8px',
  boxShadow: '0 4px 16px rgba(103, 113, 121, 0.15)',
}));
