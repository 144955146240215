import { Box } from '@mui/material';
import CardMediaMui from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';

export const CardMedia = styled(CardMediaMui)(({ theme }) => ({
  height: '300px',
  width: '100%',
  objectFit: 'cover',
  marginBottom: theme.spacing(10),
})) as typeof CardMediaMui;

export const ArrowsWrapper = styled(
  Box,
  { shouldForwardProp: (props) => props !== 'rotationDegrees' },
)<{ rotationDegrees: number }>(({ theme, rotationDegrees }) => ({
  '& svg': {
    height: '200px',
    width: '300px',
    fill: theme.palette.primary.main,
    transform: `rotate(${rotationDegrees}deg)`,
  },
}));
