import { Grid2 as Grid, Typography } from '@mui/material';
import { FC } from 'react';

import { Card } from 'containers/ProductDetailsPage/StrainType/index.styled';

import { Tag } from 'types/product.interface';

interface StrainTypeProps {
  strainType: Tag;
}

const StrainType:FC<StrainTypeProps> = ({ strainType }) => (
  <Grid container spacing={1}>
    <Grid size={[12, 6, 12, 6]}>
      <Card>
        <Typography fontWeight={700} variant="body2">
          Strain Type:
          {' '}
          {strainType.displayName}
        </Typography>
      </Card>
    </Grid>
  </Grid>
);

export default StrainType;
