import MuiCardMedia from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';

import { HEADER_HEIGHT } from 'constants/general';
import { getShopConfiguration } from 'utils/storeUtils';

const { asFullPage } = getShopConfiguration();

export const StyledHeader = styled('div')(({ theme }) => ({
  height: `${HEADER_HEIGHT}px`,
  top: 0,
  backgroundColor: theme.palette.headerBackground.main,
  position: asFullPage ? 'fixed' : 'relative',
  zIndex: theme.zIndex.drawer - 2,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
}));

export const CardMedia = styled(MuiCardMedia)(({ theme }) => ({
  objectFit: 'contain',
  maxWidth: '200px',
  maxHeight: `calc(${HEADER_HEIGHT}px - ${theme.spacing(1.2)})`,
  [theme.breakpoints.down('md')]: {
    maxWidth: '120px',
  },
})) as typeof MuiCardMedia;

export const ActionWrapper = styled('div')(({ theme }) => ({
  '& > *': {
    marginRight: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0.75),
    },
  },
  display: 'flex',
  alignItems: 'center',
}));
