import CallMadeIcon from '@mui/icons-material/CallMade';
import { Box } from '@mui/material';
import { off, onValue, query } from 'firebase/database';
import React, {
  FC, useContext, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import HowToPay from 'containers/KioskPaymentMethods/HowToPay';
import PreparingOrder from 'containers/KioskPaymentMethods/PreparingOrder';
import { PaymentContext } from 'context/PaymentContext';
import { SystemDispatchContext } from 'context/SystemContext';
import { UserContext, UserDispatchContext } from 'context/UserContext';

import { useReceipt } from 'hooks/useReceipt';
import { getOrderDetailsRef, payOrder } from 'services/Order';

import { rotationDegreesByTerminalPosition } from 'constants/checkout';
import { CHECKOUT_COMPLETE_ROUTE, HOME_ROUTE } from 'constants/clientRoutes';
import {
  LayoutType, OrderStatus, PaymentMethod, ReceiptStatus,
} from 'constants/enums';
import { PAYMENT_METHOD } from 'constants/fields';
import { Bounty } from 'types/bounty.interface';
import { OrderDetails } from 'types/order.interface';
import { KioskSettings } from 'types/shopSettings.interface';
import { handleApiErrors } from 'utils/errorUtils';
import { formatRoute } from 'utils/formatters';
import { getPublicUrlForImage } from 'utils/publicUrl';

import { ArrowsWrapper, CardMedia } from './index.styled';

enum PaymentSteps {
  HowToPay = 'HOW_TO_PAY',
  WaitForCard = 'WAIT_FOR_CARD',
  PreparingOrder = 'PREPARING_ORDER',
}

interface KioskPaymentMethodsProps {
  onClose: () => void;
  onPlaceOrder: (
    { ignoreDialog, shouldRedirect }: { ignoreDialog: boolean; shouldRedirect: boolean }
  ) => Promise<string>;
  hasPaymentFailed: boolean;
  onPaymentFailed: (hasPaymentFailed: boolean) => void;
  kioskSettings: KioskSettings;
  onChangeCheckout: (value: any, name: string) => void;
  isSubmitting?: boolean;
}

const KioskPaymentMethods: FC<KioskPaymentMethodsProps> = ({
  onClose,
  onPlaceOrder,
  hasPaymentFailed,
  onPaymentFailed,
  kioskSettings,
  onChangeCheckout,
  isSubmitting = false,
}) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { paymentDetails } = useContext(PaymentContext);
  const { setLayoutType } = useContext(SystemDispatchContext);
  const { renewKioskUser } = useContext(UserDispatchContext);
  const { orderId, alleavesOrderId, deliveryMethod } = paymentDetails || {};
  const [order, setOrder] = useState<OrderDetails | null>(null);
  const { receiptStatus } = useReceipt(orderId, deliveryMethod);
  const [paymentSteps, setPaymentSteps] = useState<PaymentSteps>(PaymentSteps.HowToPay);

  useEffect(() => {
    if (receiptStatus === ReceiptStatus.Scanned) {
      handleRedirectUser({ showSuccessMessage: true });
    }
  }, [receiptStatus]);

  const handlePayOrder = async (orderId: string) => {
    try {
      await payOrder(orderId, kioskSettings?.kioskId);
      setPaymentSteps(PaymentSteps.WaitForCard);

      if (!hasPaymentFailed) {
        subscribeToOrderDetails(orderId);
      }

      onPaymentFailed(false);
      setLayoutType(LayoutType.Default);
    } catch (error: any) {
      handleApiErrors(error);
    }
  };

  const handlePayWithCardHere = async () => {
    let id = order?.orderId || orderId;

    if (!hasPaymentFailed) {
      onChangeCheckout(PaymentMethod.DEBIT, PAYMENT_METHOD.name);
      id = await onPlaceOrder({ ignoreDialog: true, shouldRedirect: false });

      if (!id) {
        onClose();
        toast.error('Something went wrong with the order, please try again.');
        return;
      }
    }

    await handlePayOrder(id);
  };

  const subscribeToOrderDetails = (orderId: string) => {
    const dbQuery = query(getOrderDetailsRef(user?.uid, orderId));

    onValue(dbQuery, (snapshot) => {
      const orderSnapshot = snapshot.val() as Record<string, Bounty>;

      if (orderSnapshot?.bounty?.order && orderSnapshot?.bounty?.order?.status) {
        setOrder(orderSnapshot?.bounty?.order);
      }
    });
  };

  const unsubscribeFromOrderReceipt = (orderId: string) => off(getOrderDetailsRef(user?.uid, orderId));

  const checkOrderStatus = async (placedOrder: OrderDetails) => {
    if (placedOrder.status === OrderStatus.PAYED) {
      unsubscribeFromOrderReceipt(placedOrder?.orderId);
      setPaymentSteps(PaymentSteps.PreparingOrder);
    }

    if (placedOrder.status === OrderStatus.PAYMENT_FAILED) {
      const errorMessage = placedOrder.paymentInfo.failureMessage;
      toast.error(errorMessage || 'Something went wrong with the payment, please try again.');

      setPaymentSteps(PaymentSteps.HowToPay);
      onPaymentFailed(true);
      setLayoutType(LayoutType.Warning);
    }
  };

  useEffect(() => () => {
    setLayoutType(LayoutType.Default);
  }, []);

  useEffect(() => {
    if (order?.status) {
      checkOrderStatus(order);
    }
  }, [order?.status]);

  const handleRedirectUser = async ({ showSuccessMessage } = { showSuccessMessage: false }) => {
    try {
      await renewKioskUser();
      navigate(HOME_ROUTE);

      if (showSuccessMessage) {
        toast.success('Digital receipt sent to your mobile device.');
      }
    } catch (e) {
      handleApiErrors(e);
    }
  };

  const handlePayAtVendor = async () => {
    if (!hasPaymentFailed) {
      onChangeCheckout(PaymentMethod.CASH, PAYMENT_METHOD.name);
      const id = await onPlaceOrder({ ignoreDialog: true, shouldRedirect: true });

      if (!id) {
        onClose();
      }

      return;
    }

    navigate({
      pathname: formatRoute(CHECKOUT_COMPLETE_ROUTE, { orderId }),
      search: `?alleavesOrderId=${alleavesOrderId || ''}`,
    });
  };

  return (
    <Box width="100%" display="flex" justifyContent="center" alignItems="center" height="100%">
      <Box display="flex" flexDirection="column" alignItems="center">
        {paymentSteps === PaymentSteps.HowToPay && (
          <HowToPay
            onClose={onClose}
            isSubmitting={isSubmitting}
            onPayAtVendor={handlePayAtVendor}
            onPayWithCardHere={handlePayWithCardHere}
            hasPaymentFailed={hasPaymentFailed}
          />
        )}

        {paymentSteps === PaymentSteps.WaitForCard && (
          <>
            <CardMedia
              component="img"
              alt="waiting for card"
              src={getPublicUrlForImage('images/credit_card.gif')}
            />
            <ArrowsWrapper rotationDegrees={rotationDegreesByTerminalPosition[kioskSettings.terminalPosition]}>
              <CallMadeIcon />
            </ArrowsWrapper>
          </>
        )}

        {paymentSteps === PaymentSteps.PreparingOrder && order && (
          <PreparingOrder
            onDone={handleRedirectUser}
            receiptUrl={order.receiptUrl}
            orderNumber={alleavesOrderId}
          />
        )}
      </Box>
    </Box>
  );
};

export default KioskPaymentMethods;
