import {
  createContext, FC, ReactNode, useState,
} from 'react';
import { Outlet } from 'react-router-dom';

import { PaymentDetailsOfPlacedOrder } from 'types/order.interface';

const PaymentContext = createContext({
  paymentDetails: {} as PaymentDetailsOfPlacedOrder,
});

const PaymentDispatchContext = createContext({
  savePayment: (payment: PaymentDetailsOfPlacedOrder) => {}, // eslint-disable-line
  removePayment: () => {}, // eslint-disable-line
});

interface PaymentProviderProps {
  children?: ReactNode|null;
}

const PaymentProvider:FC<PaymentProviderProps> = ({ children }) => {
  const [paymentDetails, setPaymentDetails] = useState<PaymentDetailsOfPlacedOrder>({} as PaymentDetailsOfPlacedOrder);

  const savePayment = (payment: PaymentDetailsOfPlacedOrder): void => {
    setPaymentDetails(payment);
  };

  const removePayment = (): void => {
    setPaymentDetails({} as PaymentDetailsOfPlacedOrder);
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <PaymentContext.Provider value={{ paymentDetails }}>
      {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
      <PaymentDispatchContext.Provider value={{
        savePayment,
        removePayment,
      }}
      >
        <Outlet />
        {children}
      </PaymentDispatchContext.Provider>
    </PaymentContext.Provider>
  );
};

export {
  PaymentProvider,
  PaymentContext,
  PaymentDispatchContext,
};
