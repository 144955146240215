import { Box, Grid, SxProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  FC, useContext,
} from 'react';

import ProductCard from 'components/ProductCard';
import UnstyledLink from 'components/UnstyledLink';
import { LocationContext } from 'context/LocationContext';
import { SystemContext } from 'context/SystemContext';

import { getProductDetailsRoute } from 'hooks/useRouting';

import { ViewMode } from 'constants/enums';
import { Bounty } from 'types/bounty.interface';
import { Promotion } from 'types/promotion.interface';

interface RecommendationsBannerProps {
  recommendations: Promotion[];
  sx?: SxProps;
  onAddToCard: (bounty: Bounty) => void;
}

const RecommendationsBanner: FC<RecommendationsBannerProps> = ({
  recommendations,
  sx,
  onAddToCard,
}) => {
  const { shopSettings } = useContext(SystemContext);
  const { onboardingInfo } = useContext(LocationContext);
  const isDescriptionVisible = shopSettings?.viewConfigs?.PRODUCT_DESCRIPTION?.viewMode === ViewMode.Full;

  return (
    <Box sx={sx}>
      <Typography variant="h2" fontWeight={600} mb={4}>
        Today&apos;s Recommendations
      </Typography>

      <Grid container spacing={2}>
        {recommendations?.map((recommendation) => {
          const bounty = recommendation?.action?.bounties?.[0];

          return bounty ? (
            <Grid item xs={12} md={6} lg={4} key={bounty?.id}>
              <UnstyledLink
                to={getProductDetailsRoute(bounty.shortId)}
                height="100%"
                width="100%"
              >
                <ProductCard
                  bounty={bounty}
                  isDescriptionVisible={isDescriptionVisible}
                  useType={onboardingInfo?.useType}
                  hasAddButton
                  onAdd={onAddToCard}
                />
              </UnstyledLink>
            </Grid>
          ) : null;
        })}
      </Grid>
    </Box>
  );
};

export default RecommendationsBanner;
