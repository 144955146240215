import { isEmpty } from 'lodash';
import { useContext, useState } from 'react';

import { CartContext } from 'context/CartContext';

import { createCartSummary } from 'services/Order';

import { ProductUseType } from 'constants/enums';
import { CartPriceDetails, CartSummary, ProductInfo } from 'types/cart.interface';
import { formatSummaryProducts, getDiscountsTotal, getTaxesTotal } from 'utils/cartUtils';
import { formatCartDetails } from 'utils/checkoutUtils';
import { getOnboardingInfo } from 'utils/storageUtils';

const useSummary = () => {
  const cart = useContext(CartContext);

  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [updatedProducts, setUpdatedProducts] = useState({} as { [key: string]: ProductInfo });
  const [cartPriceDetails, setCartPriceDetails] = useState({} as CartSummary);

  const checkSummary = async (coupon = '') => {
    try {
      setIsLoading(true);

      const { useType } = getOnboardingInfo() || {};
      const payload = {
        coupon,
        products: cart?.products,
        useType: useType
          ? ProductUseType[useType as keyof typeof ProductUseType]
          : null,
      };

      const { data } = await createCartSummary(payload);

      const {
        discounts, total, subTotal, taxes, products, balances, roundingAmount, itemsTotal,
      } = data?.sections?.IN_APP || {};
      const { taxesTotal, taxesCurrency } = getTaxesTotal(taxes);
      const { discountsTotal, discountCurrency } = getDiscountsTotal(discounts);
      const formattedProducts = Array.isArray(products) ? formatSummaryProducts(products) : products;

      const cartDetails = {
        total,
        subTotal,
        taxesTotal,
        taxesCurrency,
        discountsTotal,
        discountCurrency,
        roundingAmount,
        balances,
        itemsTotal,
      };

      setCartPriceDetails(cartDetails);

      if (!isEmpty(formattedProducts)) {
        setUpdatedProducts(formattedProducts);
      } else {
        setIsDisabled(false);
      }

      return {
        ...cartDetails,
        products: formattedProducts,
      };
    } catch (e) {
      setUpdatedProducts({});
      setIsDisabled(false);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    checkSummary,
    setIsDisabled,
    cartPriceDetails,
    isDisabled,
    updatedProducts,
    formattedCartDetails: !isEmpty(cartPriceDetails) ? formatCartDetails(cartPriceDetails) : {} as CartPriceDetails,
    isLoading,
  };
};

export default useSummary;
