import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  onValue, off, query, limitToLast,
} from 'firebase/database';
import {
  FC, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { toast } from 'react-toastify';

import Loader from 'components/Loader';
import { UserContext } from 'context/UserContext';
import ChatHeader from 'modules/support-chat/components/ChatHeader';
import Message from 'modules/support-chat/components/Message';
import ChatInput from 'modules/support-chat/containers/ChatInput';
import { ChatArea, View } from 'modules/support-chat/containers/ChatWindow/index.styled';

import { getChatMessagesRef, markUserChatAsRead } from 'services/Chat';

import { CHAT_MESSAGES_LIMIT } from 'constants/general';
import { Attachment } from 'types/attachment.interface';
import { ChatMessage } from 'types/chat.interface';
import { addNewFile, formatBytes } from 'utils/attachmentsUtils';

import { getLastMessageId } from './utils';

interface ChatWindowProps {
  onClose: () => void;
  roomId: string;
  isDisabled: boolean;
}

const ALLOWED_FILE_SIZE = 10485760;

const ChatWindow:FC<ChatWindowProps> = ({ onClose, roomId, isDisabled }) => {
  const chatAreaRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [attachToUpload, setAttachToUpload] = useState<any>([]);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [lastRequestedMessagesCount, setLastRequestedMessagesCount] = useState<number>(CHAT_MESSAGES_LIMIT);
  const [totalCount, setTotalCount] = useState(0);
  const [lastMessageId, setLastMessageId] = useState<string | null>(null);
  const { user } = useContext(UserContext);

  const theme = useTheme();
  const is4K = useMediaQuery(theme.breakpoints.only('xl'));
  const messageLimit = useMemo(() => (is4K ? 2 * CHAT_MESSAGES_LIMIT : CHAT_MESSAGES_LIMIT), []);

  const hasMore = useMemo(() => totalCount > lastRequestedMessagesCount, [totalCount, lastRequestedMessagesCount]);

  useEffect(() => {
    const newLastMessageId = getLastMessageId(messages);

    if (newLastMessageId && lastMessageId !== newLastMessageId) {
      setLastMessageId(newLastMessageId);
      handleScrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    if (roomId) {
      subscribeOnMessages(messageLimit);
    }

    return () => {
      unsubscribeFromMessages();
      setMessages([]);
    };
  }, [roomId]);

  useEffect(() => {
    if (isDisabled && isLoading) {
      setIsLoading(false);
    }
  }, [isDisabled]);

  const unsubscribeFromMessages = () => off(getChatMessagesRef(roomId));

  const subscribeOnMessages = (count: number) => {
    const dbQuery = query(getChatMessagesRef(roomId), limitToLast(count));

    onValue(dbQuery, (snapshot) => {
      const data = snapshot.val() as { [key: string]: ChatMessage } || {};
      const ids = Object.keys(data);

      setMessages(ids.map((key) => ({
        ...data[key],
        id: key,
      })) as ChatMessage[]);
      handlePagination(ids);

      markUserChatAsRead(roomId, new Date().getTime());
      setIsLoading(false);
    }, () => setIsLoading(false));
  };

  const loadMoreMessages = () => {
    if (!roomId || messages.length < lastRequestedMessagesCount) {
      return;
    }

    const reqCount = lastRequestedMessagesCount + messageLimit;
    setLastRequestedMessagesCount(reqCount);
    unsubscribeFromMessages();
    subscribeOnMessages(reqCount);
  };

  const handlePagination = (batch: string[]) => {
    const batchLength = batch.length || 0;
    const expectedLength = lastRequestedMessagesCount || messageLimit;

    if (batchLength < expectedLength) {
      setTotalCount(batchLength);
    } else {
      setTotalCount(batchLength + 1);
    }
  };

  const scrollToOffset = (offset: number) => {
    if (chatAreaRef.current) {
      chatAreaRef.current.scrollTo(0, offset);
    }
  };

  const handleScrollToBottom = () => {
    scrollToOffset(chatAreaRef.current.scrollHeight - chatAreaRef.current.clientHeight);
  };

  const handleNewFile = (file: any) => {
    if (file.size > ALLOWED_FILE_SIZE) {
      toast.warning(`Maximum size of a single attachment shall be up to ${formatBytes(ALLOWED_FILE_SIZE)}`);
    } else {
      setAttachToUpload((prevState: any) => ([...prevState, { ...file, id: prevState.length + 1 }]));
    }
  };

  const handleOnDrop = async (acceptedFiles: File[]) => {
    acceptedFiles.forEach((attach) => {
      const newAttach: Partial<Attachment> = addNewFile(attach) || {};
      const reader = new FileReader();

      reader.onload = () => {
        if (typeof reader?.result === 'string') {
          newAttach.url = reader.result;
          handleNewFile(newAttach);
        }
      };

      reader.readAsDataURL(attach);
    });
  };

  const removeAttachment = (attachment: Attachment) => {
    const filteredAttachments = attachToUpload.filter((attach: any) => attach.id !== attachment.id);
    setAttachToUpload(filteredAttachments);
  };

  return (
    <View>
      <ChatHeader
        onClose={onClose}
        handleOnDrop={handleOnDrop}
      />
      <ChatArea ref={chatAreaRef}>
        <InfiniteScroll
          initialLoad={false}
          useWindow={false}
          pageStart={0}
          loadMore={loadMoreMessages}
          hasMore={hasMore}
          isReverse
        >
          {isLoading && <Loader />}
          {messages.map((message) => (
            <Message
              key={message.id}
              message={message}
              currentUser={user}
            />
          ))}
        </InfiniteScroll>
      </ChatArea>
      <ChatInput
        roomId={roomId}
        attachToUpload={attachToUpload}
        removeAttachment={removeAttachment}
        setAttachToUpload={setAttachToUpload}
      />
    </View>
  );
};

export default ChatWindow;
