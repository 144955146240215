import { Typography } from '@mui/material';
import { FC } from 'react';

import Loader from 'components/Loader';

import PaymentIcon from 'assets/icons/PaymentIcon';

import { BackButton, PayAtVendor, PayWithCard } from './index.styled';

interface HowToPayProps {
  onClose: () => void;
  onPayAtVendor: () => void;
  onPayWithCardHere: () => void;
  isSubmitting: boolean;
  hasPaymentFailed: boolean;
}

const HowToPay: FC<HowToPayProps> = ({
  onClose,
  onPayWithCardHere,
  onPayAtVendor,
  isSubmitting,
  hasPaymentFailed,
}) => {
  const title = hasPaymentFailed
    ? 'Payment unsuccessful. Please try again.'
    : 'How do you want to pay?';

  return (
    <>
      <Typography variant="h3" textAlign="center" mb={7}>
        {title}
      </Typography>

      <PayWithCard
        id="btn-pay-at-vendor"
        onClick={onPayWithCardHere}
        variant="outlined"
        icon={<PaymentIcon size={150} />}
      >
        Pay with card here
      </PayWithCard>

      <Typography variant="h5" textAlign="center" my={3}>
        OR
      </Typography>

      <PayAtVendor
        id="btn-pay-at-vendor"
        onClick={onPayAtVendor}
        variant="outlined"
      >
        Pay at the counter
      </PayAtVendor>

      <BackButton
        id="btn-payment-back"
        onClick={onClose}
        variant="contained"
      >
        Back
      </BackButton>

      {isSubmitting && <Loader />}
    </>
  );
};

export default HowToPay;
