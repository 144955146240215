import { styled } from '@mui/material/styles';

export const StyledBox = styled('div')<{ hide?: boolean }>(({ theme, hide }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.border.main}`,
  display: 'flex',
  justifyContent: 'center',
  color: theme.palette.general.lightGrey1,
  marginTop: 'auto',
  visibility: hide ? 'hidden' : 'visible',
}));
