import { IconButton } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';

export const StyledSection = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: '4px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all .15s ease-in-out',
  '&:hover': {
    transform: 'scale(1.015)',
    backgroundColor: theme.palette.background.secondary,
  },
}));

export const StyledImage = styled(CardMedia)(() => ({
  maxHeight: '150px',
  maxWidth: '100%',
  objectFit: 'contain',
})) as typeof CardMedia;

export const StyledPriceBox = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 'auto',
}));

export const StyledAddToCard = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
  '&:disabled': {
    pointerEvents: 'auto',
    backgroundColor: theme.palette.primary.main,
    opacity: 0.35,
  },
}));

export const StyledSkeletonSection = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: '4px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all .15s ease-in-out',
}));
