/* eslint max-len: 0 */

import { ReactElement } from 'react';

const PaymentIcon = ({ size = 28 }: { size?: number }): ReactElement => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 64 64"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path d="M277 504 c-4 -4 -7 -18 -7 -31 l0 -23 186 0 185 0 -3 28 -3 27 -176 3 c-96 1 -178 -1 -182 -4z" />
      <path d="M68 383 l-57 -58 -7 -92 -7 -93 64 0 c83 0 119 11 169 52 23 18 76 46 118 63 60 23 78 35 80 52 5 36 -24 43 -108 27 -41 -8 -76 -14 -77 -14 -2 0 -3 27 -3 60 l0 60 -58 0 c-57 0 -58 -1 -114 -57z" />
      <path d="M270 389 l0 -31 48 7 c26 3 56 9 67 11 29 7 75 -31 75 -62 0 -14 -11  -36 -25 -50 l-25 -25 113 3 112 3 3 81 3 81 -128 6 c-70 4 -154 7 -185 7 l-58 0 0 -31z" />
      <path d="M325 211 c-47 -21 -68 -43 -63 -67 5 -28 27 -28 39 0 11 24 13 24 25 8 15 -21 27 -11 49 41 18 43 12 45 -50 18z" />
    </g>
  </svg>
);

export default PaymentIcon;
