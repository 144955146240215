import { Box } from '@mui/material';
import { useContext } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';

import KioskInactivityChecker from 'containers/KioskInactivityChecker';
import { CartProvider } from 'context/CartContext';
import { CategoryProvider } from 'context/CategoryContext';
import { LocationContextProvider } from 'context/LocationContext';
import { SystemContext } from 'context/SystemContext';
import AppLayout from 'layouts/AppLayout';

import { getShopConfiguration } from 'utils/storeUtils';

const { asFullPage } = getShopConfiguration();

const GlobalProvider = () => {
  const {
    hasStores,
    clientInfo,
    detailedClientData,
    isClosed,
  } = useContext(SystemContext);
  const LocationProvider = hasStores ? LocationContextProvider : Box;

  return (
    <LocationProvider height="inherit">
      <CartProvider>
        <CategoryProvider>
          <AppLayout
            isClosed={isClosed}
            hasStores={hasStores}
            clientInfo={clientInfo}
            detailedClientData={detailedClientData}
          >
            <div>
              <Outlet />
              {asFullPage && <ScrollRestoration />}
              {detailedClientData?.kioskMode && <KioskInactivityChecker />}
            </div>
          </AppLayout>
        </CategoryProvider>
      </CartProvider>
    </LocationProvider>
  );
};

export default GlobalProvider;
