import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: '4px',
  marginBottom: theme.spacing(3),
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  cursor: 'pointer',

  '& .MuiIconButton-root': {
    border: `2px solid ${theme.palette.general.lightGrey5}`,
    color: theme.palette.common.black,

    '& .MuiSvgIcon-root': {
      width: '1.5rem',
      height: '1.5rem',
    },
  },
}));
