import { Box, Typography } from '@mui/material';
import { FC } from 'react';

import { StyledBox } from 'components/_Footer/index.styled';

import { getShopConfiguration } from 'utils/storeUtils';

const { asFullPage } = getShopConfiguration();

const Footer:FC = () => (
  <StyledBox hide={!asFullPage}>
    <Box textAlign="center">
      <Typography variant="body2">
        version
        {' '}
        {process.env.REACT_APP_VERSION}
      </Typography>
    </Box>
  </StyledBox>
);

export default Footer;
