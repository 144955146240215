import {
  Box, Dialog, SelectChangeEvent, Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import {
  ChangeEvent, useContext, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import useCheckoutConfiguration from 'components/CheckoutForm/useCheckoutConfiguration';
import CustomButton from 'components/CustomButton';
import CustomRadioButton from 'components/CustomRadioButton';
import Loader from 'components/Loader';
import { PaymentContext, PaymentDispatchContext } from 'context/PaymentContext';

import { useBillMyBank } from 'hooks/useBillMyBank';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useOrderId } from 'hooks/useRouting';
import { patchOrder } from 'services/Order';

import { CHECKOUT_COMPLETE_ROUTE } from 'constants/clientRoutes';
import { PaymentMethod } from 'constants/enums';
import { PAYMENT_METHOD } from 'constants/fields';
import { isIntegratedPayment } from 'utils/checkoutUtils';
import { handleApiErrors } from 'utils/errorUtils';
import { formatRoute } from 'utils/formatters';

const PaymentWidgetPage = () => {
  const navigate = useNavigate();
  const placedOrderId = useOrderId();
  const { paymentDetails } = useContext(PaymentContext);
  const { savePayment } = useContext(PaymentDispatchContext);
  const { paymentMethod, orderId, deliveryMethod } = paymentDetails;
  const { paymentMethods } = useCheckoutConfiguration({ deliveryMethod });
  const [newPaymentMethod, setNewPaymentMethod] = useState<PaymentMethod>(PaymentMethod.CASH);
  const [hasMissingInfo, setHasMissingInfo] = useState<boolean>(false);

  useDocumentTitle({ title: 'Payment Widget Confirmation' });

  const handleRedirectCheckout = () => {
    navigate(formatRoute(CHECKOUT_COMPLETE_ROUTE, { orderId: placedOrderId }));
  };

  const {
    startPayment, sdk, showErrorDialog, handleCloseDialog, isLoading,
  } = useBillMyBank(handleRedirectCheckout);

  const handleChangePayment = (e: ChangeEvent<HTMLInputElement> | SelectChangeEvent) => {
    const { value } = e?.target || {};
    setNewPaymentMethod(value as PaymentMethod);
  };

  const handleConfirm = async () => {
    if (isIntegratedPayment(newPaymentMethod)) {
      handleCloseDialog();
      startPayment();
      return;
    }

    savePayment({ ...paymentDetails, paymentMethod: undefined });

    try {
      const payload = { payment: { paymentMethod: newPaymentMethod } };

      await patchOrder(payload, orderId);
      savePayment({ ...paymentDetails, paymentMethod: newPaymentMethod });
      handleCloseDialog();
      navigate(formatRoute(CHECKOUT_COMPLETE_ROUTE, { orderId: placedOrderId }));
    } catch (error: any) {
      handleApiErrors(error);
    }
  };

  useEffect(() => {
    if (paymentMethod && isIntegratedPayment(paymentMethod)) {
      if (!isEmpty(sdk)) {
        startPayment();
        setHasMissingInfo(false);
        return;
      }

      setHasMissingInfo(true);
    }
  }, [paymentMethod, sdk]);

  return (
    <>
      {(showErrorDialog || hasMissingInfo) && (
        <Dialog
          open={showErrorDialog || hasMissingInfo}
          aria-describedby="paymentMethod"
        >
          {hasMissingInfo && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              px={5}
              py={2.5}
              sx={{ backgroundColor: 'error.light' }}
            >
              <div>
                <Typography variant="subtitle1" color="common.white" fontWeight={400}>
                  Currently, we are experiencing difficulty connecting with BillMyBank.
                  <br />
                  Please consider trying your transaction again shortly or opting for an alternative payment method.
                </Typography>
              </div>
            </Box>
          )}

          <Box display="flex" flexDirection="column" justifyContent="center" px={5} pt={2.5} width="100%">
            <CustomRadioButton
              {...PAYMENT_METHOD}
              options={paymentMethods}
              label="Please select a different payment method:"
              variant="body2"
              size="medium"
              onChange={handleChangePayment}
              value={newPaymentMethod}
            />

            <Box mb={4} display="flex" justifyContent="flex-end">
              <CustomButton
                id="button-confirmPaymentMethod"
                variant="contained"
                onClick={handleConfirm}
                className="button-primary"
              >
                Confirm
              </CustomButton>
            </Box>

          </Box>
        </Dialog>
      )}

      {isLoading && <Loader />}
    </>
  );
};

export default PaymentWidgetPage;
