import { Grid, SelectChangeEvent } from '@mui/material';
import { get } from 'lodash';
import { ChangeEvent, FC } from 'react';

import CustomDatePicker from 'components/CustomDatePicker';
import CustomDropdown from 'components/CustomDropdown';

import { BIRTHDAY, STATE } from 'constants/fields';
import { CustomerForm } from 'types/checkout.interface';
import { Option } from 'types/option.interface';

interface KioskUserInfoFormProps {
  onChange: (e: ChangeEvent<HTMLInputElement> | SelectChangeEvent) => void;
  onValueChange: (value: any, name: string) => void;
  customer: CustomerForm;
  fieldErrors: Record<string, string>;
  usStates: Option[];
  sx?: object;
}

const KioskUserInfoForm :FC<KioskUserInfoFormProps> = ({
  onChange,
  onValueChange,
  customer,
  fieldErrors,
  usStates,
  sx,
}) => (
  <Grid container item spacing={4} sx={sx}>
    <Grid item xs={12}>
      <CustomDatePicker
        {...BIRTHDAY}
        onChange={onValueChange}
        required={false}
        size="small"
        value={customer?.dob}
        error={!!get(fieldErrors, BIRTHDAY.name)}
        helperText={get(fieldErrors, BIRTHDAY.name)}
      />
    </Grid>
    <Grid item xs={12}>
      <CustomDropdown
        {...STATE}
        variant="outlined"
        options={usStates}
        size="small"
        onChange={(e) => onChange(e as SelectChangeEvent)}
        value={customer?.address?.state}
        error={!!get(fieldErrors, STATE.name)}
        helperText={get(fieldErrors, STATE.name)}
      />
    </Grid>
  </Grid>
);

export default KioskUserInfoForm;
