import { setDocumentTitle } from 'hooks/useDocumentTitle';
import { getNavigationSearchParams } from 'hooks/useRouting';

import config from 'config';
import { DEFAULT_SIGNIN_METHODS, LOGIN_METHOD_ID } from 'constants/general';
import { Branding, ClientInfoInterface } from 'types/clientInfo.interface';
import { getClientInfo } from 'utils/storageUtils';

import { CustomPaletteProps } from 'assets/themes/defaultTheme';
import palette from 'assets/themes/palettes/defaultPalette';

export const generateCustomPalette = (branding?: Branding) : CustomPaletteProps => {
  const {
    primaryColor, secondaryColor, headerBkgndColor, subheaderBkgndColor,
  } = branding || {};

  return {
    primary: {
      main: primaryColor?.val || palette.primary.main,
      secondary: primaryColor?.val || palette.primary.main,
      light: primaryColor?.val || palette.primary.main,
    },
    secondary: {
      main: secondaryColor?.val || palette.secondary.main,
      secondary: secondaryColor?.val || palette.secondary.main,
      light: secondaryColor?.val || palette.secondary.main,
    },
    headerBackground: {
      main: headerBkgndColor?.val || palette.headerBackground.main,
    },
    subheaderBackground: {
      main: subheaderBkgndColor?.val || palette.subheaderBackground.main,
    },
  };
};

export const getLoginMethods = (): string[] => {
  const { logins } = getClientInfo() || {};

  if (!logins) {
    return DEFAULT_SIGNIN_METHODS;
  }

  const methods = logins
    .split(',')
    .reduce((acc: string[], value: string) => {
      const providerId: string = LOGIN_METHOD_ID[value as keyof typeof LOGIN_METHOD_ID];

      if (providerId) {
        acc.push(providerId);
      }

      return acc;
    }, []);

  return methods.length ? methods : DEFAULT_SIGNIN_METHODS;
};

export const getLegalUrls = (): { tosUrl: string; privacyPolicyUrl: string } => {
  const { branding } = getClientInfo() || {};

  return {
    tosUrl: branding?.termsAndConditionsUrl || '',
    privacyPolicyUrl: branding?.privacyPolicyUrl || '',
  };
};

export const generateFavicon = () => {
  const { branding } = getClientInfo() || {};
  return branding?.websiteUrl ? `https://icon.horse/icon?uri=${branding?.websiteUrl}` : null;
};

export const getClientName = () => {
  const { name } = getClientInfo() || {};
  return name || config.title;
};

export const fallbackManifest = (clientName: string, missingClient: boolean) => {
  const name = missingClient ? config.title : clientName;
  return ({
    name,
    short_name: name,
    icons: [
      {
        src: `${window.location.origin}/images/favicon.ico`,
        sizes: '64x64 32x32 24x24 16x16',
        type: 'image/x-icon',
      },
      {
        src: `${window.location.origin}/images/android-chrome-192x192.png`,
        sizes: '192x192',
        type: 'image/png',
      },
      {
        src: `${window.location.origin}/images/android-chrome-512x512.png`,
        sizes: '512x512',
        type: 'image/png',
      },
    ],
    start_url: `${window.location.origin}/index.html`,
    scope: `${window.location.origin}/`,
    display: 'standalone',
    theme_color: '#000000',
    background_color: '#ffffff',
  });
};

export const clientManifest = (clientName: string, favicon: string) => ({
  name: clientName,
  short_name: clientName,
  icons: [
    {
      src: favicon,
      sizes: '64x64 32x32 24x24 16x16',
      type: 'image/x-icon',
    },
    {
      src: favicon,
      sizes: '42x42',
      type: 'image/png',
    },
    {
      src: favicon,
      sizes: '96x96',
      type: 'image/png',
    },
  ],
  start_url: `${window.location.origin}/index.html`,
  scope: `${window.location.origin}/`,
  display: 'standalone',
  theme_color: '#000000',
  background_color: '#ffffff',
});

export const generateManifest = (missingClient = false) => {
  const clientName = getClientName();
  const favicon = generateFavicon();
  const fallbackFavicon = `${window.location.origin}/images/favicon.ico`;
  const showFallback = missingClient || !favicon;

  const manifestJSON = showFallback
    ? fallbackManifest(clientName, missingClient)
    : clientManifest(clientName, favicon);

  const stringManifest = JSON.stringify(manifestJSON);
  const blob = new Blob([stringManifest], { type: 'application/json' });
  const manifestURL = URL.createObjectURL(blob);

  setDocumentTitle({ title: manifestJSON.short_name });

  document
    ?.querySelector('#manifest-placeholder')
    ?.setAttribute('href', manifestURL);
  document
    ?.querySelector('#favicon')
    ?.setAttribute('href', showFallback ? fallbackFavicon : favicon);
};

export const isSameClient = (slug?: string, storeId?: string) => {
  if (!slug) {
    return false;
  }

  const {
    id: clientId,
    slug: clientSlug,
    parentId,
    parentSlug,
  } = getClientInfo() || {};

  if (!storeId && parentId) {
    return [parentId, parentSlug].includes(slug);
  }

  if (!storeId) {
    return [clientSlug, clientId].includes(slug);
  }

  return [clientId, clientSlug].includes(storeId) && [parentId, parentSlug].includes(slug);
};

export const ensureBasename = (basePath: string) => {
  if (basePath && !window.location.pathname.includes(basePath)) {
    window.location.assign(basePath);
  }
};

export const ensureClientParams = (clientInfo: ClientInfoInterface) => {
  const { client, location } = getNavigationSearchParams();
  const queryParams = [];

  if (client) {
    queryParams.push(`client=${client}`);
  }

  const locationId = clientInfo.slug || clientInfo.id;
  const isLeaf = clientInfo.parentId ? locationId : null;

  if (isLeaf && locationId && locationId !== location) {
    queryParams.push(`location=${locationId}`);
    window.location.assign(`?${queryParams.join('&')}`);
  }
};
