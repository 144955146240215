import { SelectChangeEvent, Typography } from '@mui/material';
import { FC, useContext, useMemo } from 'react';

import ChipButton from 'components/ChipButton';
import CustomButton from 'components/CustomButton';
import { StyledCustomDropdown } from 'components/StoreLocationForm/index.styled';
import { LocationContext } from 'context/LocationContext';
import { OnboardingContext } from 'context/OnboardingContext';
import DeliveryRequiredInfo from 'modules/onboarding/components/DeliveryRequiredInfo';
import DialogTitle from 'modules/onboarding/components/DialogTitle';
import Stepper from 'modules/onboarding/components/Stepper';
import { DialogActions, DialogContent } from 'modules/onboarding/index.styled';

import { DeliveryMethod, MedicalIdPromptType, OnboardingSteps } from 'constants/enums';
import { STATE_WITH_PLACEHOLDER } from 'constants/fields';
import { USE_TYPE_OPTIONS } from 'constants/options';
import { ApiLocation } from 'types/companyLocations.interface';
import { OnboardingInfo } from 'types/onboarding.interface';
import { getCompanyLocationsData } from 'utils/storageUtils';

interface DeliveryMethodPickerProps {
  setup: OnboardingInfo;
  updateSettings: (data: Partial<OnboardingInfo>) => void;
  setStep: (step: OnboardingSteps) => void;
  endFlow: () => Promise<void>;
}

const DeliveryMethodPicker:FC<DeliveryMethodPickerProps> = ({
  setup,
  updateSettings,
  setStep,
  endFlow,
}) => {
  const {
    deliveryMethod,
    searchResults,
    storeId,
    state,
  } = setup;
  const { locationSettings } = useContext(OnboardingContext);
  const { onboardingInfo } = useContext(LocationContext);
  const { locationsWithStores, list, storesLocations } = getCompanyLocationsData() || {};
  const nearestState = useMemo(
    () => onboardingInfo.state || locationsWithStores?.[0].key as string || '',
    [onboardingInfo.state, locationsWithStores],
  );

  const isDisabled = () => (
    !deliveryMethod
    || (deliveryMethod === DeliveryMethod.DELIVERY && !storeId)
    || (deliveryMethod === DeliveryMethod.PICKUP && !state)
  );

  const handleSubmit = async () => {
    if (searchResults?.length === 1) {
      const selectedStore = list?.find(({ id }) => id === storeId);

      if (selectedStore?.medicalUse && selectedStore?.adultUse) {
        setStep(OnboardingSteps.UseType);
      } else if (selectedStore?.adultUse) {
        updateSettings({ useType: USE_TYPE_OPTIONS[0].key });
        endFlow();
      } else {
        updateSettings({ useType: USE_TYPE_OPTIONS[1].key });

        if (locationSettings?.medicalIdPrompt === MedicalIdPromptType.AtStart) {
          setStep(OnboardingSteps.MedicalInfo);
        } else {
          endFlow();
        }
      }
    } else {
      setStep(OnboardingSteps.UseType);
    }
  };

  const handleStateChanges = ({ target: { value } }: SelectChangeEvent<unknown>) => {
    const selectedState = value as string;
    const availableStores: ApiLocation[] = storesLocations?.[selectedState] || [];

    if (availableStores?.length === 1) {
      updateSettings({
        state: selectedState,
        storeId: availableStores?.[0]?.id,
        searchResults: availableStores,
      });
    } else {
      updateSettings({
        state: selectedState,
        storeId: '',
        searchResults: [],
      });
    }
  };

  const handleDeliveryMethod = (type: DeliveryMethod) => {
    updateSettings({
      deliveryMethod: type,
      zipcode: '',
      searchResults: [],
      storeId: '',
      state: type === DeliveryMethod.PICKUP ? nearestState : '',
    });
  };

  return (
    <>
      <DialogTitle>
        <Stepper activeStep={1} sx={{ mb: 3 }} />
        How and where
        <br />
        do you want to shop?
      </DialogTitle>

      <DialogContent>
        <div>
          <ChipButton
            hasCheckmark
            sx={{ mb: 1 }}
            onClick={() => handleDeliveryMethod(DeliveryMethod.DELIVERY)}
            isActive={deliveryMethod === DeliveryMethod.DELIVERY}
            height="44px"
          >
            <Typography fontWeight={700} className="title" variant="body1">
              DELIVERY
            </Typography>
          </ChipButton>
          <ChipButton
            hasCheckmark
            sx={{ mb: 5 }}
            onClick={() => handleDeliveryMethod(DeliveryMethod.PICKUP)}
            isActive={deliveryMethod === DeliveryMethod.PICKUP}
            height="44px"
          >
            <Typography fontWeight={700} className="title" variant="body1">
              PICKUP
            </Typography>
          </ChipButton>
        </div>
        {deliveryMethod === DeliveryMethod.DELIVERY && (
          <DeliveryRequiredInfo
            setup={setup}
            updateSettings={updateSettings}
          />
        )}
        {deliveryMethod === DeliveryMethod.PICKUP && Boolean(locationsWithStores && locationsWithStores.length > 1) && (
          <StyledCustomDropdown
            {...STATE_WITH_PLACEHOLDER}
            variant="outlined"
            value={state || ''}
            onChange={handleStateChanges}
            options={locationsWithStores || []}
          />
        )}
      </DialogContent>

      <DialogActions>
        <CustomButton
          id="button-continue"
          variant="contained"
          onClick={handleSubmit}
          disabled={isDisabled()}
        >
          Continue
        </CustomButton>
      </DialogActions>
    </>
  );
};

export default DeliveryMethodPicker;
