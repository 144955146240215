import { styled } from '@mui/material/styles';

export const CategoryCard = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 3),
  border: `1px solid ${theme.palette.border.main}`,
  cursor: 'pointer',
  marginBottom: theme.spacing(1),
  borderRadius: '4px',
}));

export const SelectedCategoryCard = styled(CategoryCard)(({ theme }) => ({
  backgroundColor: theme.palette.general.lightGrey5,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  '& .MuiIconButton-root': {
    border: `2px solid ${theme.palette.common.white}`,
    color: '#0000004D',

    '& .MuiSvgIcon-root': {
      width: '1.5rem',
      height: '1.5rem',
    },
  },
}));
