import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';
import { Box, Drawer, Typography } from '@mui/material';
import {
  MouseEvent,
  useMemo,
  useState,
} from 'react';

import StyledActionButton from 'components/StyledActionButton';
import FilterStats from 'containers/ProductFilterFlyout/FilterStats';

import useFilters from 'hooks/useFilters';

import { Filter, FilterOption } from 'types/filter.interface';

import { FilterWrapper, OptionWrapper, RemoveButton } from './index.styled';

const ProductFilterFlyout = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    activeFilters,
    filters,
    isLoading: areFiltersLoading,
    clearFilters,
    selectFilter,
    removeFilter,
  } = useFilters();
  const hasFilters = useMemo(() => areFiltersLoading || filters?.length > 0, [areFiltersLoading, filters]);

  const countActiveFilters = useMemo(() => {
    let count = 0;

    if (activeFilters) {
      Object.entries(activeFilters).forEach(([key, filter]) => {
        if (key !== 'q') {
          count += filter?.length || 0;
        }
      });
    }

    return count;
  }, [activeFilters]);

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => setIsOpen(false);

  const handleClearAll = () => {
    handleClose();
    clearFilters();
  };

  const handleRemoveFilter = (e: MouseEvent<HTMLElement>, filterId: string, filterCode: string) => {
    e.stopPropagation();

    if (areFiltersLoading) {
      return;
    }

    removeFilter(filterId, filterCode);
  };

  const handleSelectFilter = (filter: Filter, option: FilterOption) => {
    if (areFiltersLoading) {
      return;
    }
    selectFilter(filter.id, option.code, filter.type);
  };

  if (!hasFilters) {
    return null;
  }

  return (
    <div>
      <Box display="flex" alignItems="center" gap={2}>
        <StyledActionButton
          icon={<TuneIcon sx={{ transform: 'rotate(90deg)' }} />}
          text="Filters"
          onClick={handleOpen}
          count={countActiveFilters}
        />

        {activeFilters && Object.keys(activeFilters).map((filterKey) => {
          const filterDetails = filters?.find((filter) => filter?.id === filterKey);
          const count = activeFilters?.[filterKey]?.length;

          return count > 0 && filterKey !== 'q'
            ? <FilterStats key={filterKey} name={filterDetails?.name} count={count} />
            : null;
        })}
      </Box>

      {isOpen && (
        <Drawer
          variant="temporary"
          anchor="right"
          open={isOpen}
          onClose={handleClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          PaperProps={{
            sx: { px: 5, py: 7, minWidth: '500px' },
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={7}>
            <Typography variant="body1" fontWeight={700}>
              Filters
            </Typography>
            <Typography
              variant="body1"
              fontWeight={700}
              sx={{ cursor: 'pointer' }}
              color="primary.main"
              onClick={handleClearAll}
            >
              Clear All
            </Typography>
          </Box>

          <div>
            {filters.map((filter) => {
              const activeCount = activeFilters?.[filter?.id]?.length;

              return (
                <div key={filter.id}>
                  <FilterWrapper>
                    <FilterStats
                      name={filter.name}
                      fontWeight={600}
                      sx={{ backgroundColor: 'common.white', border: 0, p: 0 }}
                      count={activeCount}
                    />
                  </FilterWrapper>

                  <Box display="flex" flexDirection="column" gap={0.5}>
                    {filter.options.map((option) => {
                      const isActive = !!activeFilters?.[filter?.id]?.find((code) => code === option.code);

                      return (
                        <OptionWrapper
                          isActive={isActive}
                          key={option.code}
                          onClick={() => handleSelectFilter(filter, option)}
                        >
                          <Typography variant="body1">
                            {option.name}
                          </Typography>

                          {isActive && (
                            <RemoveButton onClick={(e) => handleRemoveFilter(e, filter?.id, option?.code)}>
                              <CloseIcon />
                            </RemoveButton>
                          )}
                        </OptionWrapper>
                      );
                    })}
                  </Box>

                </div>
              );
            })}
          </div>
        </Drawer>
      )}
    </div>
  );
};

export default ProductFilterFlyout;
