import { Typography } from '@mui/material';
import {
  FC, useMemo,
} from 'react';
import { toast } from 'react-toastify';

import Dot from 'components/Dot';
import UnstyledLink from 'components/UnstyledLink';
import KioskQuantitySelector from 'containers/ProductDetailsPage/KioskQuantitySelector';
import KioskWeightSelector from 'containers/ProductDetailsPage/KioskWeightSelector';

import { PRODUCT_DETAILS_ROUTE } from 'constants/clientRoutes';
import { Bounty } from 'types/bounty.interface';
import { formatRoute } from 'utils/formatters';
import { formatChemicalComposition, getProductStrainType, shouldUseProductWeight } from 'utils/productUtils';

import { Root } from './index.styled';

interface ProductCardOverlayProps {
  bounty: Bounty;
  onBack: () => void;
}

const ProductCardOverlay: FC<ProductCardOverlayProps> = ({
  bounty,
  onBack,
}) => {
  const { product, tags } = bounty;
  const strainType = tags?.tags ? getProductStrainType(tags?.tags) : null;
  const SelectorComponent = shouldUseProductWeight(bounty?.product)
    ? KioskWeightSelector
    : KioskQuantitySelector;

  const chemicalCompositionString = useMemo(() => (
    formatChemicalComposition(product)
  ), [product?.chemicalComposition]);

  const handleSuccess = () => {
    onBack();
    toast.success('Product was added to the cart!');
  };

  return (
    <Root>
      <div>
        {!!product?.name && (
          <UnstyledLink to={formatRoute(PRODUCT_DETAILS_ROUTE, { productId: bounty.id })}>
            <Typography fontWeight="700" gutterBottom>
              {product?.name}
            </Typography>
          </UnstyledLink>
        )}

        {(strainType || chemicalCompositionString) && (
          <Typography component="div" color="textSecondary" variant="body2" my={1}>
            {!!strainType && strainType.displayName}
            {!!strainType && !!chemicalCompositionString && <Dot display="inline-block" mb={0.5} mx={1} />}
            {!!chemicalCompositionString && chemicalCompositionString}
          </Typography>
        )}
      </div>

      <SelectorComponent
        bounty={bounty}
        cancelLabel="Close"
        submitLabel="Add"
        onCancel={onBack}
        setShowConfirmation={handleSuccess}
        isSmall
      />
    </Root>
  );
};

export default ProductCardOverlay;
