import MuiSearchIcon from '@mui/icons-material/Search';
import {
  Badge, Dialog, DialogActions, useTheme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  ChangeEvent, FC, FormEvent, SyntheticEvent, useContext, useEffect, useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';
import { SystemContext } from 'context/SystemContext';

import { useKiosk } from 'hooks/useKiosk';
import { getProductsRoute } from 'hooks/useRouting';

import {
  CancelIcon, SearchIcon, StyledButton, StyledShoppingCart, DialogContent, Root,
} from './index.styled';

interface SearchProductsProps {
  searchValue?: string;
}

const SearchProducts: FC<SearchProductsProps> = ({ searchValue = '' }) => {
  const { clientInfo } = useContext(SystemContext);
  const { kioskMode } = useKiosk();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [isFocused, setIsFocused] = useState(false);
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const categoryCode = searchParams?.get('category') || 'all';
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const useAsPopup = Boolean(isMobile || kioskMode);

  const handleClose = () => {
    setIsOpen(false);
    setSearch('');
  };

  useEffect(() => {
    setSearch(searchValue);
    setIsOpen(!!searchValue);
  }, [searchValue]);

  const handleOpen = () => {
    setIsFocused(true);
    setIsOpen(true);
  };

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSearch(value);
  };

  const handleSearch = (e: SyntheticEvent | FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (useAsPopup) {
      handleClose();
    }

    const searchParam = search
      ? `&filter.q=${encodeURIComponent(search)}`
      : '';
    const route = getProductsRoute(`category=all${searchParam}`);
    navigate(route);
  };

  const handleClearSearch = () => {
    setSearch('');

    if (!useAsPopup) {
      navigate(getProductsRoute(`category=${categoryCode}`));
      setIsOpen(false);
    }
  };

  const handleBlur = () => {
    if (!useAsPopup && !search) {
      setIsOpen(false);
    }
  };

  return (
    <Root kioskMode={kioskMode}>
      {!useAsPopup && isOpen
        ? (
          <form onSubmit={handleSearch}>
            <CustomInput
              name="search-products"
              size="small"
              fullWidth
              autoFocus={isFocused}
              placeholder={`Search in ${clientInfo?.name || ''}...`}
              onChange={handleChange}
              value={search}
              onBlur={handleBlur}
              InputProps={{
                endAdornment: search ? <CancelIcon onClick={handleClearSearch} /> : null,
                startAdornment: <SearchIcon onClick={handleSearch} sx={{ mr: 1 }} />,
              }}
              className="searchInput"
            />
            <input type="submit" hidden />
          </form>
        ) : (
          <>
            <StyledButton
              disableRipple
              aria-label="cart"
            >
              <StyledShoppingCart kioskMode={kioskMode} onClick={handleOpen}>
                <Badge color="primary">
                  <MuiSearchIcon />
                </Badge>
              </StyledShoppingCart>
            </StyledButton>

            {isOpen && (
              <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-describedby="search-products"
                disableRestoreFocus
                PaperProps={{
                  sx: {
                    borderRadius: 4, pt: 3, pb: 4, px: 2,
                  },
                }}
              >
                <DialogContent>
                  <form onSubmit={handleSearch}>
                    <CustomInput
                      name="search-products"
                      size="small"
                      fullWidth
                      placeholder={`Search in ${clientInfo?.name || ''}...`}
                      onChange={handleChange}
                      value={search}
                      autoFocus
                      InputProps={{
                        endAdornment: search ? <CancelIcon onClick={handleClearSearch} /> : null,
                        startAdornment: <SearchIcon sx={{ mr: 1 }} />,
                      }}
                    />
                    <input type="submit" hidden />
                  </form>
                </DialogContent>

                <DialogActions sx={{ p: '0 24px' }}>
                  <CustomButton id="button-cancelSearch" variant="outlined" onClick={handleClose}>
                    Cancel
                  </CustomButton>
                  <CustomButton id="button-submitSearch" variant="contained" onClick={handleSearch}>
                    Search
                  </CustomButton>
                </DialogActions>
              </Dialog>
            )}
          </>
        )}
    </Root>
  );
};

export default SearchProducts;
