import { Box, Typography } from '@mui/material';
import { FC } from 'react';

import CartTotal from 'components/CartTotal';
import { StyledBox } from 'components/CheckoutSummary/index.styled';
import Coupon from 'components/Coupon';
import CustomButton from 'components/CustomButton';
import FailedSummaryMessage from 'components/FailedSummaryMessage';
import PreviewCartProduct from 'components/PreviewCartProduct';

import { OnboardingUseType } from 'constants/enums';
import { CartPriceDetails, ProductInfo } from 'types/cart.interface';

interface CheckoutSummaryProps {
  products: ProductInfo[];
  handlePlaceOrder: () => void;
  cartPriceDetails: CartPriceDetails;
  isSubmitting: boolean;
  isSummaryLoading: boolean;
  hasSummaryFailed?: boolean;
  consentMessage?: string;
  isKiosk?: boolean;
  onChangeSummary: (coupon: string) => void;
  useType: OnboardingUseType;
}

const CheckoutSummary: FC<CheckoutSummaryProps> = ({
  handlePlaceOrder,
  products,
  cartPriceDetails,
  isSubmitting,
  consentMessage,
  isSummaryLoading,
  hasSummaryFailed = false,
  isKiosk = false,
  onChangeSummary,
  useType,
}) => (
  <div className="checkoutSummary">
    <StyledBox>
      <CartTotal
        cartPriceDetails={cartPriceDetails}
        isLoading={(isSummaryLoading && !isSubmitting)}
        hasFailed={hasSummaryFailed}
      />

      <Typography variant="body2" my={2}>
        The tax amount we provide is an estimate and is subject to change. Final taxes will be adjusted when you pay
        for your order.
      </Typography>

      <CustomButton
        id="button-placeOrder"
        fullWidth
        variant="contained"
        onClick={handlePlaceOrder}
        isLoading={isSubmitting || isSummaryLoading}
        disabled={hasSummaryFailed}
      >
        Place order
      </CustomButton>

      {consentMessage && (
        <Typography variant="body2" fontSize={12} mt={3}>
          {consentMessage}
        </Typography>
      )}
    </StyledBox>

    <Box mt={3}>
      <Coupon onChangeSummary={onChangeSummary} />
    </Box>

    {hasSummaryFailed && <FailedSummaryMessage sx={{ mt: 3 }} />}

    {!isKiosk && (
      <Box p={3}>
        <Typography fontWeight={700} mb={2}>
          Items (
          {products.length}
          )
        </Typography>
        <Box width="100%">
          {products.map((product) => (
            <PreviewCartProduct product={product} key={product?.id} useType={useType} />
          ))}
        </Box>
      </Box>
    )}
  </div>
);

export default CheckoutSummary;
