import SendIcon from '@mui/icons-material/Send';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import {
  ChangeEvent, FC, useContext, useEffect, useRef, useState,
} from 'react';
import { toast } from 'react-toastify';

import { UserContext } from 'context/UserContext';
import AttachmentsHolder from 'modules/support-chat/components/AttachmentsHolder';
import { Root } from 'modules/support-chat/containers/ChatInput/index.styled';

import { getNewChatMessageRef, writeMessage } from 'services/Chat';
import { uploadChatAttachments } from 'services/Storage';

import { Attachment } from 'types/attachment.interface';
import { Message } from 'types/chat.interface';
import { attachmentsAsObject } from 'utils/attachmentsUtils';
import { handleApiErrors } from 'utils/errorUtils';

interface ChatInputProps {
  roomId: string;
  attachToUpload: Attachment[];
  removeAttachment: (attachment: Attachment) => void;
  setAttachToUpload: (attachment: Attachment[]) => void;
}

const ChatInput:FC<ChatInputProps> = ({
  roomId,
  attachToUpload,
  removeAttachment,
  setAttachToUpload,
}) => {
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, isLoading]);

  const handleEnter = (e: KeyboardEvent | any) => {
    if (!e.shiftKey && e.keyCode === 13) {
      handleSubmitMessage();
    }
  };

  const handleTyping = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setMessage(value);
  };

  const handleSubmitMessage = async () => {
    if (message.trim() === '' && !attachToUpload?.length) {
      setMessage('');
      return;
    }

    try {
      setIsLoading(true);
      const messageRef = getNewChatMessageRef(roomId);
      const msgId = messageRef.key;

      if (msgId) {
        const attachments = attachToUpload ? await uploadChatAttachments(attachToUpload, user.uid, msgId) : [];
        const attachs: Attachment[] = attachments
          .filter(({ status }) => status === 'fulfilled')
          .map(({ value }: any) => value);

        const msg: Message = {
          msgId,
          msg: message,
        };

        if (attachments?.length) {
          msg.attachments = { attachments: attachmentsAsObject(attachs) };
        }

        await writeMessage(roomId, msg);
        setMessage('');
        setAttachToUpload([]);

        if (attachs?.length !== attachments?.length) {
          toast.warning('Some files could not be uploaded.');
        }
      } else {
        toast.error('Something went wrong, please try again!');
      }
    } catch (err: any) {
      handleApiErrors(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Root>
      {attachToUpload?.length > 0 && (
        <AttachmentsHolder
          isSubmitting={isLoading}
          attachments={attachToUpload}
          removeAttachment={removeAttachment}
        />
      )}
      <TextField
        inputRef={inputRef}
        fullWidth
        size="small"
        placeholder="Send message..."
        multiline
        maxRows={4}
        onChange={handleTyping}
        onKeyDown={handleEnter}
        value={message}
        disabled={isLoading || !roomId}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={isLoading || !roomId}
                aria-label="send message"
                onClick={handleSubmitMessage}
                size="small"
                color="primary"
                id="iconButton-chat"
              >
                <SendIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Root>
  );
};

export default ChatInput;
