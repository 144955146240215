import CancelIcon from '@mui/icons-material/Cancel';
import { LinearProgress, Box } from '@mui/material';
import { FC } from 'react';

import Attachment from 'components/Attachment';
import { ImageBox, RemoveAttachment, Root } from 'modules/support-chat/components/AttachmentsHolder/index.styled';

import { Attachment as AttachmentInterface } from 'types/attachment.interface';

interface AttachmentsHolderProps {
  isSubmitting: boolean;
  attachments: AttachmentInterface[];
  removeAttachment: (attach: any) => void;
}

const AttachmentsHolder:FC<AttachmentsHolderProps> = ({ attachments, removeAttachment, isSubmitting }) => (
  <Root>
    {isSubmitting
      ? <Box width="100%"><LinearProgress /></Box>
      : (
        attachments.map((attach, key) => (
          <ImageBox key={key}>
            <Attachment
              attachment={attach}
              height="70px"
              width="70px"
            />
            <RemoveAttachment size="small" onClick={() => removeAttachment(attach)}>
              <CancelIcon fontSize="small" />
            </RemoveAttachment>
          </ImageBox>
        ))
      )}
  </Root>
);

export default AttachmentsHolder;
