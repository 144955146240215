import { Box } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import { OrderStatus } from 'constants/enums';

export const StyledBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
  borderRadius: '4px',
  padding: theme.spacing(2),
  height: '100%',
}));

export const GeneralDetails = styled(StyledBox)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.only('xs')]: {
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

export const StyledStatusWrapper = styled(Box)<{ status?: OrderStatus }>(({ theme, status }) => ({
  borderTop: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
  borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
  padding: theme.spacing(2),
  backgroundColor: status && [OrderStatus.DECLINED, OrderStatus.CANCELLED].includes(status)
    ? theme.palette.general.darkYellow1
    : theme.palette.subheaderBackground.main,
  display: 'flex',
  alignItems: 'center',
}));
