import { SelectChangeEvent, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  FC, useContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import CustomSelector from 'components/CustomSelector';
import CategoriesMobile from 'containers/Categories/CategoriesMobile';
import KioskCategories from 'containers/Categories/KioskCategories';
import { CategoryContext } from 'context/CategoryContext';

import { useKiosk } from 'hooks/useKiosk';
import { getProductsRoute } from 'hooks/useRouting';

import { Category } from 'types/category.interface';
import { formatCategoryOptions } from 'utils/formatters';

const Categories:FC = () => {
  const { kioskMode } = useKiosk();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { categories } = useContext(CategoryContext);
  const matchesScreenSizeSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [category, setCategory] = useState<string[]>([]);
  const categoryCodes = searchParams?.get('category');
  const selectors = useMemo(() => {
    const temp = [categories];

    if (!category?.length || category[0] === 'all') {
      return temp;
    }

    category.forEach((val, index) => {
      const root = temp[index]?.find(({ code }) => code === val);

      if (root?.children) {
        temp.push(root.children);
      }
    });

    return temp;
  }, [category, categories]);

  useEffect(() => {
    if (categoryCodes) {
      setCategory(categoryCodes.split(','));
    }
  }, [categoryCodes]);

  const handleCategoryChanges = (value: string, index: number) => {
    let newCategories = [...category];
    newCategories[index] = value as string;
    newCategories = newCategories.slice(0, value === 'all' && index ? index : index + 1);
    const route = getProductsRoute(`category=${newCategories.join(',')}`);

    return navigate(route);
  };

  const handleSelect = ({ target: { value } }: SelectChangeEvent<unknown>, index: number) => {
    handleCategoryChanges(value as string, index);
  };

  const getSelectedValue = (index: number, currCategories: Category[]) => {
    if (category[index]) return category[index];
    if (currCategories?.length === 1) return currCategories[0].code;
    return 'all';
  };

  if (kioskMode) {
    return (
      <KioskCategories
        selectors={selectors}
        selectedCategories={category}
        onCategoryChange={handleCategoryChanges}
      />
    );
  }

  if (matchesScreenSizeSm) {
    return (
      <CategoriesMobile
        selectors={selectors}
        selectedCategories={category}
        onCategorySelect={handleSelect}
      />
    );
  }

  return (
    selectors?.map((arr, index) => (
      <CustomSelector
        variant="outlined"
        key={index}
        displayEmpty
        value={getSelectedValue(index, arr)}
        onChange={(e) => handleSelect(e, index)}
        name="category"
        options={formatCategoryOptions(arr, index)}
      />
    ))
  );
};

export default Categories;
