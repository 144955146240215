import { Dialog } from '@mui/material';
import {
  FC, useContext, useEffect, useState,
} from 'react';

import Loader from 'components/Loader';
import { LocationContext } from 'context/LocationContext';
import { OnboardingContext, OnboardingContextDispatchContext } from 'context/OnboardingContext';
import { SystemContext } from 'context/SystemContext';
import AgeSelector from 'modules/onboarding/components/AgePicker';
import MedicalInfo from 'modules/onboarding/components/MedicalInfo';
import DeliveryMethodPicker from 'modules/onboarding/containers/DeliveryMethodPicker';
import DeliveryPicker from 'modules/onboarding/containers/DeliveryPicker';
import UseTypePicker from 'modules/onboarding/containers/UseTypePicker';

import { AgeFilterType, OnboardingSteps, OnboardingUseType } from 'constants/enums';
import { OnboardingInfo } from 'types/onboarding.interface';
import { ShopSettings } from 'types/shopSettings.interface';
import { getOnboardingInfo } from 'utils/storageUtils';
import { getStoreDetailsFromStorage, isSetupCompleted } from 'utils/storeUtils';

const OnboardingFlow:FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [step, setStep] = useState<OnboardingSteps|null>(null);
  const [setup, setSetup] = useState<OnboardingInfo>({
    age: 0,
    state: '',
    storeId: '',
    storeName: '',
    companyId: '',
    useType: OnboardingUseType.is_medical_use,
    zipcode: '',
    searchResults: [],
  });

  const { shopSettings, clientInfo } = useContext(SystemContext);
  const { setupCompleted } = useContext(LocationContext);
  const { allowedAge } = useContext(OnboardingContext);
  const { isModalOpen, locationSettings } = useContext(OnboardingContext);
  const {
    fetchLocationSettings,
    closeOnboardingFlow,
    getNearestLocation,
    closeModal,
  } = useContext(OnboardingContextDispatchContext);

  const hasMultipleServiceTypes = shopSettings?.deliveryMethods
    ? shopSettings?.deliveryMethods?.split(',')?.length > 1
    : false;
  const closeOptions = setupCompleted
    ? { onClose: closeModal }
    : {};

  useEffect(() => {
    if (!isSetupCompleted(shopSettings, true)) {
      chooseFlow();
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const onboardingInfo = getOnboardingInfo();

    if (onboardingInfo) {
      const { filterByAge } = locationSettings || {};
      setSetup({
        ...onboardingInfo,
        age: allowedAge && filterByAge && filterByAge !== AgeFilterType.None && filterByAge > onboardingInfo.age
          ? 0
          : onboardingInfo.age,
      });
    }
  }, []);

  const handleEndFlow = async () => {
    try {
      if (setup.storeId !== clientInfo.id) {
        const store = getStoreDetailsFromStorage(setup.storeId);
        const companyId = store?.companyId || setup.storeId;

        const newLocation = await fetchLocationSettings(companyId);
        const { filterByAge } = newLocation || {} as ShopSettings;

        if (filterByAge && filterByAge !== AgeFilterType.None && filterByAge > setup.age) {
          updateSettings({ age: 0 });
          return;
        }
      }

      closeOnboardingFlow(setup);
    } catch (e) {
      closeOnboardingFlow(setup);
    }
  };

  const updateSettings = (data: Partial<OnboardingInfo>) => {
    setSetup((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const chooseFlow = async () => {
    try {
      const result = await getNearestLocation();

      if (result) {
        const { companyId, storeId } = result;
        const { filterByAge } = await fetchLocationSettings(companyId || storeId) || {} as ShopSettings;

        // if the location has age filter, save the location info and show age picker, otherwise end onboarding flow
        if (filterByAge && filterByAge !== AgeFilterType.None) {
          updateSettings(result);
          setIsLoading(false);
        } else {
          closeOnboardingFlow(result);
        }
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  const renderSetup = () => (
    hasMultipleServiceTypes ? (
      <>
        {(!step || step === OnboardingSteps.DeliveryType) && (
          <DeliveryMethodPicker
            setup={setup}
            updateSettings={updateSettings}
            setStep={setStep}
            endFlow={handleEndFlow}
          />
        )}
        {step === OnboardingSteps.UseType && (
          <UseTypePicker
            setup={setup}
            updateSettings={updateSettings}
            setStep={setStep}
            endFlow={handleEndFlow}
          />
        )}
        {step === OnboardingSteps.MedicalInfo && (
          <MedicalInfo
            hasSteps
            setup={setup}
            updateSettings={updateSettings}
            setStep={setStep}
            endFlow={handleEndFlow}
          />
        )}
      </>
    ) : (
      <>
        {(!step || step === OnboardingSteps.UseType) && (
          <DeliveryPicker
            setup={setup}
            updateSettings={updateSettings}
            setStep={setStep}
            endFlow={handleEndFlow}
            onClose={closeModal}
          />
        )}

        {step === OnboardingSteps.MedicalInfo && (
          <MedicalInfo
            setup={setup}
            updateSettings={updateSettings}
            setStep={setStep}
            endFlow={handleEndFlow}
          />
        )}
      </>
    )
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Dialog
      open={isModalOpen}
      maxWidth="sm"
      fullWidth
      aria-describedby="select-store-location"
      PaperProps={{ sx: { maxWidth: 480, borderRadius: '16px' } }}
      {...closeOptions}
    >
      {!setup.age && allowedAge
        ? (
          <AgeSelector
            allowedAge={allowedAge}
            setup={setup}
            onClose={closeModal}
            updateSettings={updateSettings}
          />
        ) : renderSetup()}
    </Dialog>
  );
};

export default OnboardingFlow;
