import { isEmpty, isEqual } from 'lodash';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { LocationContext } from 'context/LocationContext';

import { getProductsRoute } from 'hooks/useRouting';
import { getFilters } from 'services/Product';

import { FilterType } from 'constants/enums';
import { Filter } from 'types/filter.interface';
import { getCategoryLeaf } from 'utils/categoriesUtils';
import { formatFilters } from 'utils/formatters';
import { getStoreId, getUseType, setFiltersByStore } from 'utils/storageUtils';

interface FiltersProps {
 [key: string]: string[];
}

const useFilters = ({ shouldRedirect } = { shouldRedirect: false }) => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<Filter[]>([]);
  const [activeFilters, setActiveFilters] = useState<FiltersProps>({});
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const { onboardingInfo } = useContext(LocationContext);
  const storeId = useMemo(() => getStoreId() || 'storeId', [onboardingInfo?.storeId]);
  const useType = useMemo(() => getUseType(), [onboardingInfo?.useType]);

  useEffect(() => {
    const initialFilters = formatFilters(searchParams);
    setActiveFilters(initialFilters);
    fetchFilters(initialFilters);
  }, []);

  const fetchFilters = async (currentFilters: FiltersProps) => {
    try {
      setIsLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { q, ...categoryFilters } = currentFilters;
      const searchFilter = searchParams?.get('filter.q') || '';
      const formattedSearch = searchFilter.replace(/\s+/g, '+');
      const categoryFilter = getCategoryLeaf(searchParams?.get('category')) || 'all';
      const params = new URLSearchParams();

      if (!isEmpty(categoryFilters)) {
        Object.values(categoryFilters).forEach((filters) => {
          filters.forEach((filter) => {
            params.append('tag', filter);
          });
        });
      }

      if (useType) {
        params.append('tag', useType);
        params.append('tag', 'is_non_cannabis');
      }
      params.append('category', categoryFilter);

      if (formattedSearch) {
        params.append('q', formattedSearch);
      }

      const { data } = await getFilters(params);
      const filterWithOptions = data?.filterDefs?.filter((item: Filter) => (
        currentFilters?.[item.id] ? item.options?.length : item.options?.length > 1
      )) || [];

      if (!isEqual(filterWithOptions, filters)) {
        setFilters(filterWithOptions);
        setFiltersByStore(storeId, filterWithOptions);
      }
    } catch (e) {
      setFilters([]);
    } finally {
      setIsLoading(false);
    }
  };

  const selectFilter = (filterId: string, filterCode: string, filterType: FilterType) => {
    if (shouldRedirect) {
      const newFilterValue = filterType === FilterType.MULTI_SELECT
        ? [...(activeFilters?.[filterId] ?? []), filterCode]
        : [filterCode];
      const route = getProductsRoute(`filter.${filterId}=${newFilterValue.join(',')}`);
      navigate(route);
    }

    const newFilterValue = filterType === FilterType.MULTI_SELECT
      ? [...(activeFilters?.[filterId] ?? []), filterCode]
      : [filterCode];

    const currentFilters = {
      ...activeFilters,
      [filterId]: newFilterValue,
    };
    setActiveFilters(currentFilters);
    updateSearchParams(currentFilters);
    fetchFilters(currentFilters);
  };

  const removeFilter = (filterId: string, filterCode: string) => {
    const newFilterValue = activeFilters?.[filterId]?.filter((code) => code !== filterCode);
    const currentFilters = {
      ...activeFilters,
      [filterId]: newFilterValue,
    };

    setActiveFilters(currentFilters);
    updateSearchParams(currentFilters);
    fetchFilters(currentFilters);
  };

  const clearFilters = () => {
    Object.keys(activeFilters).forEach((filterKey) => {
      searchParams.delete(`filter.${filterKey}`);
    });

    setSearchParams(searchParams);
    setActiveFilters({});
  };

  const updateSearchParams = (currentFilters: FiltersProps) => {
    Object
      .keys(currentFilters)
      .forEach((filterKey) => {
        if (currentFilters[filterKey]?.length) {
          searchParams.set(`filter.${filterKey}`, currentFilters[filterKey].join(','));
        } else {
          searchParams.delete(`filter.${filterKey}`);
        }
      });

    setSearchParams(searchParams);
  };

  return {
    filters,
    activeFilters,
    isLoading,
    selectFilter,
    removeFilter,
    clearFilters,
    updateSearchParams,
  };
};

export default useFilters;
