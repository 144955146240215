import {
  Container, Typography, CardMedia, Box,
} from '@mui/material';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getHomeRoute } from 'hooks/useRouting';

import ProductAdded from 'assets/images/added_to_cart.gif';

interface CartConfirmationProps {
  cartTotal: string;
}

const CartConfirmation:FC<CartConfirmationProps> = ({ cartTotal }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => navigate(getHomeRoute()), 2400);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Container maxWidth="sm">
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        <CardMedia
          sx={{ width: '200px', height: '200px' }}
          component="img"
          src={ProductAdded}
          alt="product added to cart"
        />
        <Typography variant="h5" fontWeight="500" textAlign="center">
          Item added to cart
        </Typography>

        {cartTotal && (
          <>
            <Typography variant="body2" color="textSecondary" textAlign="center">
              Order total updated
            </Typography>
            <Typography variant="h5" fontWeight="500" textAlign="center">
              {cartTotal}
            </Typography>
          </>
        )}
      </Box>
    </Container>
  );
};

export default CartConfirmation;
