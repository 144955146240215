import { Typography } from '@mui/material';
import { FC } from 'react';

import QrCodeWrapper from 'components/QrCodeWrapper';

import { DoneButton, OrderNumber } from './index.styled';

interface PreparingOrderProps {
  onDone: () => void;
  receiptUrl: string;
  orderNumber: string;
}

const PreparingOrder: FC<PreparingOrderProps> = ({
  onDone,
  receiptUrl,
  orderNumber,
}) => (
  <>
    <Typography variant="h4" textAlign="center" mb={5}>
      We are preparing your order
      <br />
      scan the QR Code to see the details
    </Typography>
    <QrCodeWrapper
      size={150}
      src={receiptUrl}
      sx={{ mb: 3, p: 2, borderRadius: '4px' }}
      innerSx={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2,
      }}
      backgroundColor="common.white"
    />
    <OrderNumber>
      <Typography variant="h4" textAlign="center" mb={5}>
        Order
      </Typography>
      <Typography variant="h3" textAlign="center">
        {`#${orderNumber}`}
      </Typography>
    </OrderNumber>
    <DoneButton
      id="btn-done"
      onClick={onDone}
      variant="contained"
    >
      Done
    </DoneButton>
  </>
);

export default PreparingOrder;
