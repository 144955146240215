import { FC } from 'react';

import { Step, Wrapper } from 'modules/onboarding/components/Stepper/index.styled';

interface StepperProps {
  activeStep: number;
  sx?: object;
}

const Stepper:FC<StepperProps> = ({ activeStep, sx }) => (
  <Wrapper sx={sx}>
    <Step isActive={activeStep === 1}>
      1
    </Step>
    <Step isActive={activeStep === 2}>
      2
    </Step>
  </Wrapper>
);

export default Stepper;
