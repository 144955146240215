import {
  Box, Container, Typography, useTheme,
} from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import BackButton from 'components/BackButton';
import BackCard from 'components/BackCard';
import CustomButton from 'components/CustomButton';
import QrCodeWrapper from 'components/QrCodeWrapper';
import SectionTitle from 'components/SectionTitle';
import SubheaderWrapper from 'components/SubheaderWrapper';
import { PaymentContext } from 'context/PaymentContext';
import { SystemContext } from 'context/SystemContext';
import { UserDispatchContext } from 'context/UserContext';

import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useReceipt } from 'hooks/useReceipt';
import {
  getDigitalReceiptRoute, getHomeRoute, getOrderDetailsRoute, useOrderId,
} from 'hooks/useRouting';

import { DeliveryMethod, ReceiptStatus } from 'constants/enums';
import { getPublicUrlForImage } from 'utils/publicUrl';

const OrderConfirmationPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const placedOrderId = useOrderId() || '';
  const { paymentDetails } = useContext(PaymentContext);
  const { detailedClientData } = useContext(SystemContext);
  const { renewKioskUser } = useContext(UserDispatchContext);
  const theme = useTheme();
  const matchesScreenSizeSm = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    alleavesOrderId, deliveryMethod, receiptUrl, orderId,
  } = paymentDetails;
  const alleavesOrderIdParam = searchParams?.get('alleavesOrderId');
  const displayedOrderId = alleavesOrderId || alleavesOrderIdParam;

  const { receiptStatus } = useReceipt(placedOrderId, deliveryMethod);
  const isQrCodeVisible = [DeliveryMethod.PICKUP, DeliveryMethod.KIOSK].includes(deliveryMethod) && receiptUrl;
  const homeRoute = getHomeRoute();

  useDocumentTitle({ title: 'Order Confirmation' });

  useEffect(() => {
    if (detailedClientData?.kioskMode) {
      renewKioskUser();
    }
  }, []);

  const handleSeeOrder = () => {
    navigate(getOrderDetailsRoute(placedOrderId));
  };

  const handleSeeAllProducts = () => {
    navigate(homeRoute);
  };

  const handleRedirectToReceipt = () => (orderId ? navigate(getDigitalReceiptRoute(orderId)) : null);

  useEffect(() => {
    if (receiptStatus === ReceiptStatus.Scanned) {
      handleRedirectToReceipt();
      toast.success('Digital receipt sent to your mobile device.');
    }
  }, [receiptStatus]);

  return (
    <>
      {detailedClientData?.kioskMode ? (
        <Container>
          <BackCard onClick={() => navigate(homeRoute)} text="See all products" sx={{ width: '300px' }} />
        </Container>
      ) : (
        <SubheaderWrapper>
          <Box display="flex" alignItems="center">
            <BackButton href={homeRoute} label="See all products" />
            <SectionTitle variant="subheader" title="Thank you" sx={{ ml: 2.5 }} />
          </Box>
        </SubheaderWrapper>
      )}

      <Container>
        <Box display="flex" alignItems="center" flexDirection="column" mt={3}>
          <CardMedia
            component="img"
            src={getPublicUrlForImage('images/shipped_order.gif')}
            alt="shippedOrderGif"
            sx={{
              objectFit: 'contain',
              width: '100%',
              height: '210px',
            }}
          />

          {isQrCodeVisible
            ? (
              <>
                <Typography variant="h6" fontWeight={600} mt={1.5} mb={4} textAlign="center">
                  {`Order ${displayedOrderId ? `#${displayedOrderId}` : ''}`}
                  <br />
                  placed successfully. Thank you!
                </Typography>

                {!detailedClientData?.kioskMode && (
                  <CustomButton
                    id="button-seeOrder"
                    variant="outlined"
                    onClick={handleSeeOrder}
                    className="button-secondary"
                  >
                    View order details
                  </CustomButton>
                )}
              </>
            ) : (
              <>
                <Typography variant="h6" fontWeight={600} mt={3} mb={2}>
                  Thank you for your purchase!
                </Typography>

                <Typography variant="body2" mb={4}>
                  {`Order ${displayedOrderId ? `#${displayedOrderId}` : ''} has been placed successfully!`}
                </Typography>

                {!detailedClientData?.kioskMode && (
                  <CustomButton
                    id="button-seeOrder"
                    variant="outlined"
                    onClick={handleSeeOrder}
                    sx={{ mb: 1.5 }}
                    className="button-secondary"
                  >
                    See order
                  </CustomButton>
                )}

                <CustomButton
                  id="button-seeAllProducts"
                  variant="outlined"
                  onClick={handleSeeAllProducts}
                  className="button-secondary"
                >
                  See all products
                </CustomButton>
              </>
            )}

          {isQrCodeVisible && (
            <QrCodeWrapper
              size={matchesScreenSizeSm ? 75 : 125}
              src={receiptUrl}
              sx={{ mt: 10 }}
              innerSx={{
                display: 'flex', flexDirection: 'column', gap: 2.5, alignItems: 'center',
              }}
              isMobileView={matchesScreenSizeSm}
              onRedirect={handleRedirectToReceipt}
            >
              <Typography variant="body2" textAlign="center">
                {matchesScreenSizeSm
                  ? 'View digital receipt.'
                  : 'Scan this QR Code with your phone to receive your digital receipt.'}
                <br />
                <b>
                  {matchesScreenSizeSm
                    ? 'At pickup, please show it to the vendor'
                    : 'At pickup, please show the resulting page to the vendor.'}
                </b>
              </Typography>
            </QrCodeWrapper>
          )}
        </Box>
      </Container>
    </>
  );
};

export default OrderConfirmationPage;
