import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.main}`,
  padding: theme.spacing(1, 1.5),
  borderRadius: '32px',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));
