import { Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { FC } from 'react';

import { getCartPriceValue } from 'utils/cartUtils';

import { CustomTypographyVariant } from 'assets/themes/defaultTheme';

interface CartPriceProps {
  price?: string;
  isLoading?: boolean;
  hasFailed?: boolean;
  variant?: Variant | CustomTypographyVariant;
}

const CartPrice: FC<CartPriceProps> = ({
  price = '$0.00',
  isLoading = false,
  hasFailed = false,
  variant = 'body1',
}) => (
  <Typography variant={variant}>
    {getCartPriceValue({ value: price, isLoading, hasFailed })}
  </Typography>
);

export default CartPrice;
