import { styled } from '@mui/material/styles';

import CustomButton from 'components/CustomButton';

export const BackButton = styled(CustomButton)(() => ({
  width: '250px',
  height: '60px',

  '&:disabled': {
    height: '60px',
  },
})) as typeof CustomButton;

export const PayWithCard = styled(CustomButton)(({ theme }) => ({
  padding: theme.spacing(5),
  boxShadow: '0 4px 16px rgba(103, 113, 121, 0.15)',
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  marginTop: theme.spacing(3),
  borderColor: theme.palette.border.main,
  backgroundColor: theme.palette.background.main,

  '& path': {
    fill: theme.palette.primary.main,
  },
  '&:hover': {
    backgroundColor: theme.palette.background.secondary,
  },
  '&:disabled': {
    opacity: 0.8,
  },
})) as typeof CustomButton;

export const PayAtVendor = styled(CustomButton)(({ theme }) => ({
  width: '350px',
  height: '80px',
  boxShadow: '0 4px 16px rgba(103, 113, 121, 0.15)',
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(12),
  borderColor: theme.palette.border.main,
  backgroundColor: theme.palette.background.main,

  '&:hover': {
    backgroundColor: theme.palette.background.secondary,
  },
  '&:disabled': {
    opacity: 0.8,
    height: '80px',
  },
})) as typeof CustomButton;
