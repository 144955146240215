import {
  Container, Grid2 as Grid, Box,
} from '@mui/material';
import { isNil } from 'lodash';
import {
  Suspense, lazy, FC, useContext,
} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { toast } from 'react-toastify';

import EmptyState from 'components/EmptyState';
import ProductCardSkeleton from 'components/ProductCard/index.skeleton';
import UnstyledLink from 'components/UnstyledLink';
import Categories from 'containers/Categories';
import HighlightsBanner from 'containers/HighlightsBanner';
import { ActionsWrapper } from 'containers/KioskProducts/index.styled';
import ProductFilterFlyout from 'containers/ProductFilterFlyout';
import ProductSortFlyout from 'containers/ProductSortFlyout';
import PromotionBanner from 'containers/PromotionBanner';
import RecommendationsBanner from 'containers/RecommendationsBanner';
import { CartDispatchContext } from 'context/CartContext';
import { CategoryContext } from 'context/CategoryContext';
import { LocationContext } from 'context/LocationContext';
import { SystemContext } from 'context/SystemContext';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';

import { PRODUCT_DETAILS_ROUTE } from 'constants/clientRoutes';
import { BannerType, ProductSortOptions, ViewMode } from 'constants/enums';
import { Bounty } from 'types/bounty.interface';
import { Promotion } from 'types/promotion.interface';
import { formatRoute } from 'utils/formatters';
import { shouldUseProductWeight } from 'utils/productUtils';

const ProductCard = lazy(() => import('components/ProductCard'));

interface KioskProductsProps {
  promotions?: Record<BannerType, Promotion[]>;
  onLoadProducts: () => void;
  skeletonProducts: number[];
  products: Bounty[] | null;
  hasMore: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  onSort: (newSort: ProductSortOptions) => void;
  sortOption: ProductSortOptions;
}

const KioskProducts: FC<KioskProductsProps> = ({
  promotions,
  onLoadProducts,
  skeletonProducts,
  products,
  hasMore,
  isLoadingMore,
  isLoading,
  onSort,
  sortOption,
}) => {
  const { categories } = useContext(CategoryContext);
  const { shopSettings } = useContext(SystemContext);
  const { onboardingInfo } = useContext(LocationContext);
  const { handleTrackAddItemToCart, handleTrackViewItem } = useGoogleAnalytics();
  const { addItem, addItemWithWeight } = useContext(CartDispatchContext);
  const hasCategories = categories?.length > 0;
  const isDescriptionVisible = shopSettings?.viewConfigs?.PRODUCT_DESCRIPTION?.viewMode === ViewMode.Full;
  const {
    [BannerType.Hero]: heroes = [],
    [BannerType.Recommendations]: recommendations = [],
    [BannerType.Highlights]: highlights = [],
  } = promotions || {};
  const isListVisible = !(highlights?.length > 0 && recommendations?.length > 0);

  const addToCard = (bounty: Bounty) => {
    if (shouldUseProductWeight(bounty?.product)) {
      const weight = bounty?.product?.sortWeight?.toString() || '';
      addItemWithWeight({
        bounty,
        weight,
        onSuccess: () => {
          toast.success('Product added to cart!');
          handleTrackAddItemToCart({ bounty, weight });
        },
      });
    } else {
      addItem({
        bounty,
        quantity: 1,
        onSuccess: () => {
          toast.success('Product added to cart!');
          handleTrackAddItemToCart({ bounty, selectedQuantity: 1 });
        },
      });
    }

    handleTrackViewItem(bounty);
  };

  return (
    <Box position="relative">
      <Container>
        <Grid container spacing={2}>
          {hasCategories && (
            <Grid size={4}>
              <Categories />
            </Grid>
          )}

          <Grid size={hasCategories ? 8 : 12}>
            <Box maxHeight="calc(100vh - 196px)" className="hidden-scroll">
              {heroes?.length > 0 && (
                <PromotionBanner
                  sx={{ px: heroes?.length > 1 ? 6 : 0, mt: 0 }}
                  promotions={heroes}
                />
              )}
              {highlights?.length > 0 && <HighlightsBanner highlights={highlights} sx={{ mb: 6 }} /> }
              {recommendations?.length > 0 && (
                <RecommendationsBanner
                  recommendations={recommendations}
                  onAddToCard={addToCard}
                />
              )}
            </Box>

            {isListVisible && (
              <div>
                {!isLoading && isNil(products)
                  ? <EmptyState message="No products found!" />
                  : (
                    <>
                      <ActionsWrapper>
                        <ProductSortFlyout sortOption={sortOption} onChange={onSort} />
                        <ProductFilterFlyout />
                      </ActionsWrapper>

                      <InfiniteScroll
                        pageStart={0}
                        loadMore={onLoadProducts}
                        hasMore={hasMore}
                      >
                        <Grid container spacing={2} maxHeight="calc(100vh - 286px)" className="hidden-scroll">
                          {!isLoading && products && products.map((bounty, index) => (
                            <Grid key={`${index}-${bounty.id}`} size={[12, 12, 6, 4]}>
                              <Suspense fallback={<ProductCardSkeleton />}>
                                <UnstyledLink
                                  to={formatRoute(PRODUCT_DETAILS_ROUTE, { productId: bounty.id })}
                                  height="100%"
                                  width="100%"
                                >
                                  <ProductCard
                                    bounty={bounty}
                                    isDescriptionVisible={isDescriptionVisible}
                                    useType={onboardingInfo?.useType}
                                    onAdd={addToCard}
                                    hasAddButton
                                  />
                                </UnstyledLink>
                              </Suspense>
                            </Grid>
                          ))}

                          {(isLoading || isLoadingMore) && skeletonProducts.map((loader) => (
                            <Grid key={`productSkeleton-${loader}`} size={[12, 12, 6, 4]}>
                              <ProductCardSkeleton />
                            </Grid>
                          ))}
                        </Grid>
                      </InfiniteScroll>
                    </>
                  )}
              </div>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default KioskProducts;
