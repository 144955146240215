import { Typography } from '@mui/material';
import { FC } from 'react';

import BalanceInformation from 'containers/MedicalBalance/BalanceInformation';
import { BalanceWrapper } from 'containers/MedicalBalance/index.styled';
import MissingMedicalBalance from 'containers/MedicalBalance/MissingMedicalBalance';

import { BalanceCatInfo } from 'types/cart.interface';

interface MedicalBalanceProps {
  balances?: {
    [key: string]: BalanceCatInfo;
  };
  checkSummary: () => unknown;
}

const MedicalBalance: FC<MedicalBalanceProps> = ({ balances, checkSummary }) => (
  <BalanceWrapper>
    <Typography fontWeight={700} mb={2}>
      Total Balance
    </Typography>

    {balances
      ? <BalanceInformation balances={balances} />
      : <MissingMedicalBalance checkSummary={checkSummary} />}
  </BalanceWrapper>
);

export default MedicalBalance;
