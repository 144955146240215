import { Box, Container, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import {
  FC, useContext, useMemo, useState,
} from 'react';

import Brand from 'components/Brand';
import Dot from 'components/Dot';
import FreeTag from 'components/FreeTag';
import { StyledImage } from 'components/ProductCard/index.styled';
import ProductPrice from 'components/ProductPrice';
import ProductWeight from 'components/ProductWeight';
import CartConfirmation from 'containers/ProductDetailsPage/CartConfirmation';
import ProductDetailsSkeleton from 'containers/ProductDetailsPage/KioskProductDetails/index.skeleton';
import KioskQuantitySelector from 'containers/ProductDetailsPage/KioskQuantitySelector';
import KioskWeightSelector from 'containers/ProductDetailsPage/KioskWeightSelector';
import { LocationContext } from 'context/LocationContext';

import useSummary from 'hooks/useSummary';

import { DASHBOARD_PRODUCT_COVER_SIZE } from 'constants/cover';
import { Bounty } from 'types/bounty.interface';
import { CartPriceDetails } from 'types/cart.interface';
import { getProductCover } from 'utils/attachmentsUtils';
import { formatCartDetails } from 'utils/checkoutUtils';
import { getProductPrice, getTopLevelVariant, isFree } from 'utils/priceUtils';
import { formatProductWeight, getManufacturer, shouldUseProductWeight } from 'utils/productUtils';
import { getPublicUrlForImage } from 'utils/publicUrl';

interface KioskProductDetailsProps {
  bounty: Bounty;
  isLoading: boolean;
}

const KioskProductDetails:FC<KioskProductDetailsProps> = ({ bounty, isLoading }) => {
  const { product } = bounty;
  const manufacturer = getManufacturer(product);
  const { onboardingInfo } = useContext(LocationContext);
  const { price, msrp } = getProductPrice(product, onboardingInfo?.useType);
  const [summary, setSummary] = useState({
    total: '',
    showSummary: false,
  });
  const { checkSummary, isLoading: isCheckingSummary } = useSummary();
  const { weightUnit, quantity } = getTopLevelVariant(product) || {};
  const productQuantity = Math.max(0, quantity || 0);
  const isOutOfStock = productQuantity <= 0;
  const isProductFree = isFree(price);
  const SelectorComponent = shouldUseProductWeight(bounty?.product)
    ? KioskWeightSelector
    : KioskQuantitySelector;

  const coverUrl = useMemo(() => {
    if (!product) {
      return null;
    }

    const cover = getProductCover(
      product.imageUrl,
      DASHBOARD_PRODUCT_COVER_SIZE.w,
      DASHBOARD_PRODUCT_COVER_SIZE.h,
      product.imageCdn,
    );
    return cover?.cdnUrl || cover?.url;
  }, [bounty.id]);

  const handleCartConfirmation = async () => {
    try {
      const data = await checkSummary();
      const formattedCardDetails = !isEmpty(data) ? formatCartDetails(data) : {} as CartPriceDetails;

      setSummary({
        total: formattedCardDetails.total ? formattedCardDetails.formattedTotal : '',
        showSummary: true,
      });
    } catch (e) {
      setSummary({
        total: '',
        showSummary: true,
      });
    }
  };

  if (summary.showSummary) {
    return <CartConfirmation cartTotal={summary.total} />;
  }

  const renderPrice = () => {
    if (isOutOfStock) {
      return <Typography fontWeight={700} variant="body1" color="error">Out of stock</Typography>;
    }

    if (isProductFree) {
      return <FreeTag fontWeight={700} variant="body1" />;
    }

    return <ProductPrice price={price} msrp={msrp} fontWeight={500} variant="h5" />;
  };

  return (
    <Container maxWidth="sm">
      {isLoading
        ? <ProductDetailsSkeleton />
        : (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box margin="0 auto" height={150}>
              <StyledImage
                component="img"
                src={coverUrl || getPublicUrlForImage('images/placeholder_image.jpg')}
                alt={product?.name}
              />
            </Box>
            <Box mb={1} textAlign="center">
              <Typography variant="h5" fontWeight="500">
                {product?.name}
              </Typography>
              {manufacturer && (
                <Brand brand={manufacturer} variant="body3" />
              )}
              <Box display="flex" alignItems="center" justifyContent="center" gap={1} mt={1}>
                {renderPrice()}
                {product?.sortWeight && (
                  <>
                    <Dot />
                    <ProductWeight
                      fontWeight={500}
                      variant="h5"
                      weight={formatProductWeight(product.sortWeight)}
                      weightUnit={weightUnit}
                    />
                  </>
                )}
              </Box>
            </Box>

            <Typography variant="h5" mb={1} fontWeight={500}>
              {shouldUseProductWeight(bounty?.product) ? 'Weight' : 'Units'}
            </Typography>

            <SelectorComponent
              isLoading={isCheckingSummary}
              bounty={bounty}
              setShowConfirmation={handleCartConfirmation}
            />

            <Typography my={4}>
              {product?.description}
            </Typography>
          </Box>
        )}
    </Container>
  );
};

export default KioskProductDetails;
