import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const Button = styled(
  MuiButton,
  { shouldForwardProp: (props) => props !== 'isLoading' },
)<{ isLoading: boolean }>(({ isLoading }) => ({
  '&:disabled': {
    opacity: isLoading ? 1 : 0.35,
    height: isLoading ? '40px' : 'inherit',
  },
}));
