import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import {
  Box, SelectChangeEvent, Typography,
} from '@mui/material';
import {
  FC, useContext, useMemo, useState,
} from 'react';

import ChipButton from 'components/ChipButton';
import CustomButton from 'components/CustomButton';
import { OnboardingContext } from 'context/OnboardingContext';
import DialogTitle from 'modules/onboarding/components/DialogTitle';
import Stepper from 'modules/onboarding/components/Stepper';
import { StyledCustomDropdown } from 'modules/onboarding/containers/UseTypePicker/index.styled';
import { DialogContent, DialogActions } from 'modules/onboarding/index.styled';

import { MedicalIdPromptType, OnboardingSteps, OnboardingUseType } from 'constants/enums';
import { STORE_WITH_PLACEHOLDER } from 'constants/fields';
import { USE_TYPE_OPTIONS } from 'constants/options';
import { OnboardingInfo } from 'types/onboarding.interface';
import { getCompanyLocationsData } from 'utils/storageUtils';

interface UseTypePickerProps {
  setup: OnboardingInfo;
  updateSettings: (data: Partial<OnboardingInfo>) => void;
  setStep: (step: OnboardingSteps) => void;
  endFlow: () => Promise<void>;
}

const UseTypePicker:FC<UseTypePickerProps> = ({
  setup, updateSettings, setStep, endFlow,
}) => {
  const {
    useType, state, storeId, searchResults,
  } = setup;
  const { locationSettings } = useContext(OnboardingContext);
  const { storesByStates } = getCompanyLocationsData() || {};
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const storeOptions = useMemo(
    () => (
      searchResults?.length
        ? searchResults?.map(({ id, name }: any) => ({ key: id, value: name }))
        : storesByStates?.[useType]?.[state] || []
    ),
    [useType],
  );
  const isDisable = !storeId || isSubmitDisabled;

  const handleUseTypeChanges = (value: OnboardingUseType) => {
    updateSettings({ useType: value });
  };

  const handleChange = ({ target: { value } }: SelectChangeEvent<unknown>) => {
    updateSettings({ storeId: value as string });
  };

  const handleNext = async () => {
    if (useType === USE_TYPE_OPTIONS[1].key && locationSettings.medicalIdPrompt === MedicalIdPromptType.AtStart) {
      setStep(OnboardingSteps.MedicalInfo);
    } else {
      setIsSubmitDisabled(true);
      endFlow();
    }
  };

  return (
    <>
      <DialogTitle>
        <Stepper activeStep={2} sx={{ mb: 3 }} />
        Please choose
        <br />
        your experience
      </DialogTitle>
      <DialogContent>
        <Box py={0}>
          <ChipButton
            hasCheckmark
            sx={{ mb: 1 }}
            icon={<SelfImprovementIcon className="useType" />}
            isActive={useType === USE_TYPE_OPTIONS[0].key}
            onClick={() => handleUseTypeChanges(USE_TYPE_OPTIONS[0].key)}
          >
            <div>
              <Typography fontWeight={700} className="title">
                {USE_TYPE_OPTIONS[0].value.toUpperCase()}
              </Typography>
              <Typography variant="body2">
                No medical card
              </Typography>
            </div>
          </ChipButton>

          <ChipButton
            hasCheckmark
            icon={<MedicalServicesOutlinedIcon className="useType" />}
            isActive={useType === USE_TYPE_OPTIONS[1].key}
            onClick={() => handleUseTypeChanges(USE_TYPE_OPTIONS[1].key)}
          >
            <div>
              <Typography fontWeight={700} className="title" variant="body1">
                {USE_TYPE_OPTIONS[1].value.toUpperCase()}
              </Typography>
              <Typography variant="body2">
                Medical card required
              </Typography>
            </div>
          </ChipButton>

          {storeOptions?.length > 1 && (
            <Box mt={4}>
              <StyledCustomDropdown
                {...STORE_WITH_PLACEHOLDER}
                variant="outlined"
                value={storeId || ''}
                onChange={handleChange}
                options={storeOptions}
                disabled={!useType}
              />
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <CustomButton
          id="button-goBack"
          variant="outlined"
          color="secondary"
          onClick={() => setStep(OnboardingSteps.DeliveryType)}
        >
          Back
        </CustomButton>
        <CustomButton
          id="button-continue"
          variant="contained"
          onClick={handleNext}
          disabled={isDisable}
        >
          Continue
        </CustomButton>
      </DialogActions>
    </>
  );
};

export default UseTypePicker;
