import { Typography, TypographyOwnProps } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { FC } from 'react';

import { WeightUnit } from 'constants/enums';
import { WEIGHT_UNIT_LABELS } from 'constants/labels';

interface ProductWeightProps extends TypographyOwnProps {
  weight: string;
  fontWeight: number;
  variant: Variant;
  weightUnit?: WeightUnit;
}

const ProductWeight :FC<ProductWeightProps> = ({
  weight,
  fontWeight,
  variant,
  weightUnit,
  ...remainingProps
}) => (
  <Typography {...remainingProps} variant={variant} fontWeight={fontWeight}>
    {`${weight} ${WEIGHT_UNIT_LABELS[weightUnit || WeightUnit.G]}`}
  </Typography>
);

export default ProductWeight;
