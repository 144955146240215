import MuiPaper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

export const Paper = styled(MuiPaper)(({ theme }) => ({
  borderRadius: '60px',
  width: '575px',
  height: '120px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  color: theme.palette.primary.main,
}));

export const WarningPaper = styled(MuiPaper)(({ theme }) => ({
  borderRadius: '30px',
  width: '600px',
  padding: theme.spacing(5),
}));

export const PlayerWrapper = styled('div')(() => ({
  '& iframe': {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1200,
  },
}));
