import { Box, Typography } from '@mui/material';
import { FC, ReactElement } from 'react';

import Dot from 'components/Dot';

import { IconWrapper, Root } from './index.styled';

interface StyledActionButtonProps {
  icon: ReactElement;
  text: string;
  onClick: () => void;
  count?: number;
}

const StyledActionButton: FC<StyledActionButtonProps> = ({
  icon,
  text,
  onClick,
  count = 0,
}) => (
  <Root onClick={onClick}>
    <IconWrapper>
      {icon}
    </IconWrapper>

    <Box pl={1} pr={1.5} display="flex" alignItems="center" gap={1}>
      <Typography variant="body1">
        {text}
      </Typography>

      {count > 0 && (
        <>
          <Dot sx={{ height: '6px', width: '6px' }} />
          <Typography variant="body1">
            {count}
          </Typography>
        </>
      )}
    </Box>
  </Root>
);

export default StyledActionButton;
