import { Category } from 'types/category.interface';

export const findNode = (code: string, arr: Category[]): Category | undefined | null => (
  arr.reduce((previousValue: Category | null | undefined, currentValue: Category) => {
    if (previousValue) {
      return previousValue;
    }

    if (currentValue.code === code) {
      return currentValue;
    }

    if (currentValue.children) {
      return findRoot(code, currentValue.children);
    }

    return null;
  }, null)
);

export const findRoot = (code: string, arr: Category[]): Category|undefined => (
  arr.find((item) => {
    if (item.code === code) {
      return item;
    }

    if (item.children) {
      return findNode(code, item.children);
    }

    return null;
  })
);

export const getCategoryLeaf = (category: string|null) => {
  const codes = category?.split(',') || [];
  return codes[codes.length - 1];
};
