import PersonIcon from '@mui/icons-material/Person';
import { FC } from 'react';

import { Chip } from 'components/LoginButton/index.styled';

interface LoginButtonProps {
  onClick: () => void;
}

const LoginButton:FC<LoginButtonProps> = ({ onClick }) => (
  <Chip
    label="Account"
    variant="outlined"
    onClick={onClick}
    icon={<PersonIcon />}
  />
);

export default LoginButton;
