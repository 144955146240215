import { PhoneStatus, WeightUnit } from 'constants/enums';
import { DEFAULT_PAGINATION_LIMIT, US_COUNTRY_CODE } from 'constants/general';
import { FLWR_QUANTITY_LABELS } from 'constants/labels';
import { Address, GoogleFormattedAddress } from 'types/address.interface';
import { ProductInfo } from 'types/cart.interface';
import { Category } from 'types/category.interface';
import { OnboardingInfo } from 'types/onboarding.interface';
import { PhoneInterface } from 'types/phone.interface';
import { getCategoryLeaf } from 'utils/categoriesUtils';
import { getFlwrVariantByQuantity, isFlwr } from 'utils/productUtils';
import { getOnboardingInfo } from 'utils/storageUtils';
import { checkHexColor, checkUrl } from 'utils/yupUtils';

import palette from 'assets/themes/palettes/defaultPalette';

export const formatRoute = (route: string, params: Record<string, any>): string => {
  let formattedRoute = route;

  Object.keys(params).forEach((key) => {
    formattedRoute = formattedRoute.replace(`:${key}`, params[key]);
  });

  return formattedRoute;
};

export const formatUserInitials = (name: string) => {
  const names = name.split(' ');
  return `${names[0][0]}${names[1]?.[0] || ''}`;
};

export const splitDisplayName = (displayName: string): { lastName: string; firstName: string } => {
  const parts = displayName.split(' ');

  return {
    lastName: parts.pop() || '',
    firstName: parts.join(' '),
  };
};

export const textTruncate = (str: string, length = 100, ending = '...') => {
  if (str && str.length > length) {
    return str.substring(0, length) + ending;
  }
  return str;
};

export const formatCategoryOptions = (categories: Category[], index?: number) => {
  const options = categories?.map(({ code, name }) => ({ key: code, value: name }));

  if (options?.length === 1) return options;

  return [
    { key: 'all', value: index ? 'All subcategories' : 'All categories' },
    ...options,
  ];
};

export const extractFiltersFromParams = (searchParams: URLSearchParams): URLSearchParams => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    category, merchantId, client, location, page, product, order,
    ...filters
  } = Object.fromEntries([...searchParams]);
  const params = new URLSearchParams();

  Object
    .keys(filters)
    .filter((key) => key.startsWith('filter.'))
    .forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });

  return params;
};

export const formatFilters = (searchParams: URLSearchParams) => {
  const filters = Object.fromEntries([...extractFiltersFromParams(searchParams)]);

  return Object.keys(filters)
    .reduce((acc: any, key) => {
      const filterKey = key.split('filter.')[1];
      acc[filterKey] = filters[key]?.split(',') ?? [];

      return acc;
    }, {});
};

export const formatSearchParams = (searchParams: URLSearchParams) => {
  const categoryCode = getCategoryLeaf(searchParams.get('category'));
  const searchFilters = extractFiltersFromParams(searchParams);

  searchFilters.delete('filter.q');
  searchFilters.set('length', DEFAULT_PAGINATION_LIMIT?.toString());

  const onboardingInfo = getOnboardingInfo();

  if (categoryCode) {
    searchFilters.set('category', categoryCode);
  }

  if (onboardingInfo?.useType) {
    searchFilters.set('tag', onboardingInfo.useType);
    searchFilters.append('tag', 'is_non_cannabis');
  }

  return new URLSearchParams(searchFilters);
};

export const formatClientInfoFromSearchParams = (searchParams: URLSearchParams) => ({
  branding: {
    primaryColor: {
      val: checkHexColor(searchParams.get('primaryColor') || '', palette.primary.main),
    },
    secondaryColor: {
      val: checkHexColor(searchParams.get('secondaryColor') || '', palette.secondary.main),
    },
    headerBkgndColor: {
      val: checkHexColor(searchParams.get('headerBkgndColor') || '', palette.headerBackground.main),
    },
    subheaderBkgndColor: {
      val: checkHexColor(searchParams.get('subheaderBkgndColor') || '', palette.subheaderBackground.main),
    },
    logoUrl: checkUrl(searchParams.get('logoUrl') || '', ''),
    websiteUrl: checkUrl(searchParams.get('websiteUrl') || '', ''),
  },
  id: '',
  name: searchParams.get('name') || '',
  companyId: '',
});

export const formatManufacturerForFilter = (manufacturer: string): string => {
  const displayName = manufacturer
    .toLowerCase()
    .replaceAll(/[^a-z0-9]/g, '_');

  return `brand_${displayName}`;
};

export const formatAddressParts = (address: Address): string => {
  const parts = [];

  if (address.city) {
    parts.push(address.city);
  }

  if (address.state) {
    parts.push(address.state);
  }

  if (address.postalCode) {
    parts.push(address.postalCode);
  }

  return parts.join(', ');
};

export const formatAddress = (address: Address): string => {
  const parts = [];

  if (address.line1) parts.push(address.line1);
  if (address.line2) parts.push(address.line2);

  const addressPartsAsString = formatAddressParts(address);

  if (addressPartsAsString) parts.push(addressPartsAsString);

  return parts.join(', ');
};

export const capitalize = (string: string) => {
  if (!string) return string;

  const [first, ...rest] = string;
  return first.toUpperCase() + rest.join('').toLowerCase();
};

export const formatProductsForGoogleAnalytics = (
  product: ProductInfo,
  onboardingInfo: OnboardingInfo,
) => ({
  item_id: product?.productSku,
  item_name: product?.name,
  price: product?.price?.money?.amount,
  quantity: product?.quantity,
  affiliation: onboardingInfo?.storeName,
  ...(product?.manufacturer ? { item_brand: product.manufacturer } : {}),
});

export const getPhoneFromAddress = (address?: Address | GoogleFormattedAddress): PhoneInterface => ({
  phoneNumber: address?.phoneNumber || '',
  countryCode: address?.countryCode || US_COUNTRY_CODE,
  phoneStatus: address?.phoneStatus || PhoneStatus.UNVERIFIED,
});

export const formatProductQuantity = (quantity: number, sortUnit?: WeightUnit) => {
  const variant = getFlwrVariantByQuantity(quantity, sortUnit);

  if (isFlwr(sortUnit) && variant) {
    return FLWR_QUANTITY_LABELS[variant];
  }

  return quantity;
};
