import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSummaryPrice = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  width: '100%',
  backgroundColor: theme.palette.background.secondary,
  borderRadius: '4px',
}));

export const StyledSummary = styled(
  Box,
  { shouldForwardProp: (props) => props !== 'isKiosk' },
)<{ isKiosk?: boolean }>(({ isKiosk = false }) => ({
  position: 'sticky',
  top: isKiosk ? '72px' : '130px',
  height: 'fit-content',
  width: '100%',
}));
