import { isNil } from 'lodash';
import { isValid } from 'usdl-regex';
import * as Yup from 'yup';

import { FieldRuleType } from 'constants/enums';
import { DEFAULT_STATE_CODE } from 'constants/general';
import { isAddressAutocomplete, isTribalIdAllowed } from 'utils/checkoutUtils';
import { checkValidPhone } from 'utils/phoneUtils';
import { getLocalUsStates, getOnboardingInfo } from 'utils/storageUtils';
import { doesStoreDeliverToZipcode, getAllowedAge, isAgeValid } from 'utils/storeUtils';
import { dlAllowedCharactersRegex, medicalIdRegex, zipcodeRegex } from 'validation/regex';

const yesterday = new Date(new Date(new Date().setDate(new Date().getDate())).setHours(0, 0, 0, 0));

const checkDLAllowedCharacters = (value: string) => dlAllowedCharactersRegex.test(value);

const checkUSDL = (value: any, context: any) => {
  const { parent, originalValue } = context;
  return originalValue && isValidState(parent?.state || DEFAULT_STATE_CODE, true)
    ? isValid(parent?.state || DEFAULT_STATE_CODE, originalValue)
    : true; // skip validation if no value provided
};

export const checkUserAge = (value: any) => isAgeValid(value);

const checkStoreZipcodes = (value: any) => doesStoreDeliverToZipcode(value);

const checkState = (value: any, context: any) => {
  const { parent, originalValue } = context;
  return parent?.tempState === originalValue;
};

const isValidState = (value?: string, required?: boolean) => {
  if (!value && !required) {
    return true;
  }

  const usStates = getLocalUsStates() || [];
  return Boolean(usStates.find((option) => option.key === value));
};

export const medicalRules = Yup.object({
  medicalId: Yup
    .string()
    .nullable()
    .required('Please provide your medical ID.')
    .matches(medicalIdRegex, 'Invalid medical ID.'),
  medicalIdExp: Yup
    .date()
    .nullable()
    .required('Please provide the expiration date of your medical ID.')
    .transform((castValue, originalValue) => (originalValue ? new Date(originalValue) : null))
    .min(yesterday, 'Your medical ID has expired.'),
});

export const getDriverLicenseRules = (
  { fieldsRules, ignoreRules = false }: { fieldsRules?: Record<string, FieldRuleType>; ignoreRules?: boolean },
) => {
  const genericNumberRule = Yup
    .string()
    .test(
      'checkDLAllowedCharacters',
      'Please type only letters and numbers',
      (value) => checkDLAllowedCharacters(value || DEFAULT_STATE_CODE),
    )
    .test(
      'checkUSDL',
      'Please provide a valid Driver License',
      (value, context) => (isTribalIdAllowed() ? true : checkUSDL(value, context)),
    );
  const numberRule = fieldsRules?.driverLicenseNumber === FieldRuleType.Required || ignoreRules
    ? { number: genericNumberRule.required('Driver\'s License number is required') }
    : { number: genericNumberRule };

  const genericExpDateRule = Yup
    .date()
    .nullable()
    .transform((castValue, originalValue) => (originalValue ? new Date(originalValue) : null))
    .min(yesterday, 'Your driver license has expired.');
  const expDateRule = fieldsRules?.driverLicenseExpirationDate === FieldRuleType.Required || ignoreRules
    ? { expDate: genericExpDateRule.required('Driver\'s License expiration date is required') }
    : { expDate: genericExpDateRule };

  const stateRules = fieldsRules?.driverLicenseState === FieldRuleType.Required || ignoreRules
    ? {
      state: Yup
        .string()
        .required('Driver\'s License state is required')
        .test(
          'isValidState',
          'Please select a state',
          (value) => isValidState(value, true),
        ),
    } : {
      state: Yup
        .string()
        .test(
          'isValidState',
          'Please select a state',
          (value) => isValidState(value),
        ),
    };

  return Yup.object({
    ...((fieldsRules?.driverLicenseState !== FieldRuleType.Hidden || ignoreRules) ? stateRules : {}),
    ...((fieldsRules?.driverLicenseNumber !== FieldRuleType.Hidden || ignoreRules) ? numberRule : {}),
    ...((fieldsRules?.driverLicenseExpirationDate !== FieldRuleType.Hidden || ignoreRules) ? expDateRule : {}),
  });
};

export const getCustomerRules = (fieldsRules?: Record<string, FieldRuleType>) => {
  const genericFirstName = Yup.string().nullable();
  const genericLastName = Yup.string().nullable();
  const genericEmail = Yup.string().email('Invalid email').nullable();
  const genericState = Yup.string().nullable();
  const genericDob = Yup
    .date()
    .transform((castValue, originalValue) => (originalValue ? new Date(originalValue) : null))
    .nullable()
    .test(
      'validDob',
      () => `You must be ${getAllowedAge()} years old to purchase`,
      (value) => isNil(value) || checkUserAge(value),
    );
  const genericPhone = Yup
    .string()
    .test('checkValidPhone', () => 'Invalid phone number', checkValidPhone);
  const genericCountryCode = Yup.string();

  const firstName = fieldsRules?.firstName !== FieldRuleType.Required
    ? { firstName: genericFirstName }
    : { firstName: genericFirstName.required('First name is required') };
  const lastName = fieldsRules?.lastName !== FieldRuleType.Required
    ? { lastName: genericLastName }
    : { lastName: genericLastName.required('Last name is required') };
  const email = fieldsRules?.email !== FieldRuleType.Required
    ? { email: genericEmail }
    : { email: genericEmail.required('Email is required') };
  const state = fieldsRules?.state !== FieldRuleType.Required
    ? { state: genericState }
    : { state: genericState.required('State is required') };
  const dob = fieldsRules?.dob !== FieldRuleType.Required
    ? { dob: genericDob }
    : { dob: genericDob.required('Birthday is required') };
  const phoneNumber = fieldsRules?.phoneNumber !== FieldRuleType.Required
    ? { phoneNumber: genericPhone, countryCode: genericCountryCode }
    : {
      phoneNumber: genericPhone.required('Number is required'),
      countryCode: genericCountryCode.required('Country code is required'),
    };
  const defaultAddress = {
    ...(fieldsRules?.state !== FieldRuleType.Hidden ? state : {}),
    ...(fieldsRules?.phoneNumber !== FieldRuleType.Hidden ? phoneNumber : {}),
  };
  const address = fieldsRules?.address !== FieldRuleType.Required
    ? { ...optionalCustomerAddressRules, ...defaultAddress }
    : { ...customerAddressRules, ...defaultAddress };

  return Yup.object({
    ...(fieldsRules?.firstName !== FieldRuleType.Hidden ? firstName : {}),
    ...(fieldsRules?.lastName !== FieldRuleType.Hidden ? lastName : {}),
    ...(fieldsRules?.email !== FieldRuleType.Hidden ? email : {}),
    ...(fieldsRules?.dob !== FieldRuleType.Hidden ? dob : {}),
    address: Yup.object(fieldsRules?.address !== FieldRuleType.Hidden ? address : defaultAddress),
  });
};

export const addressRules = Yup.object({
  line1: isAddressAutocomplete()
    ? Yup
      .string()
      .required('Please provide your address.')
      .when('tempState', {
        is: (tempState: string) => {
          const { state } = getOnboardingInfo() || {};
          return tempState !== state;
        },
        then: (schema) => {
          const { state } = getOnboardingInfo() || {};

          return schema.test(
            'checkState',
            `We don't deliver outside ${state}`,
            (value, context) => checkState(value, context),
          );
        },
      })
    : Yup
      .string()
      .required('Please provide your address.'),
  postalCode: Yup
    .string()
    .required('Please provide your zipcode.')
    .matches(zipcodeRegex, 'Invalid zipcode')
    .test('checkStore', 'We don’t deliver to this area', (value) => checkStoreZipcodes(value)),
  city: Yup
    .string()
    .required('Please provide your city.'),
});

export const customerAddressRules = {
  line1: Yup
    .string()
    .required('Please provide your address.'),
  postalCode: Yup
    .string()
    .required('Please provide your zipcode.')
    .matches(zipcodeRegex, 'Invalid zipcode'),
  state: Yup
    .string()
    .required('Please provide your state.'),
  city: Yup
    .string()
    .required('Please provide your city.'),
};

export const optionalCustomerAddressRules = {
  line1: Yup.string().optional(),
  postalCode: Yup
    .string()
    .optional()
    .matches(zipcodeRegex, 'Invalid zipcode'),
  state: Yup.string().optional(),
  city: Yup.string().optional(),
};
