import { Box, Container } from '@mui/material';
import { isEmpty } from 'lodash';
import { useContext, useEffect, useState } from 'react';

import BackButton from 'components/BackButton';
import EmptyState from 'components/EmptyState';
import Loader from 'components/Loader';
import OrderInformation from 'components/OrderInformation';
import SectionTitle from 'components/SectionTitle';
import SubheaderWrapper from 'components/SubheaderWrapper';
import { UserContext } from 'context/UserContext';

import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { getHomeRoute } from 'hooks/useRouting';
import { getMyOrders } from 'services/Order';

import { Bounty } from 'types/bounty.interface';
import { getShopConfiguration } from 'utils/storeUtils';

const { hideHeader } = getShopConfiguration();

const OrdersPage = () => {
  const [orders, setOrders] = useState<Bounty[]>([] as Bounty[]);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user?.uid) {
      fetchMyOrders();
    }
  }, [user?.uid]);

  useDocumentTitle({ title: 'Orders' });

  const fetchMyOrders = async () => {
    setIsLoading(true);

    try {
      const response: { data: Bounty[] } = await getMyOrders(user.uid);
      setOrders(response?.data?.filter(({ order }) => order) || []);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SubheaderWrapper>
        <Box display="flex" alignItems="center" gap={1}>
          {hideHeader && <BackButton href={getHomeRoute()} label="See all products" />}
          <SectionTitle variant="subheader" title="My Orders" />
        </Box>
      </SubheaderWrapper>

      {isLoading ? (
        <Loader />
      ) : (
        <Container>
          {!isEmpty(orders) ? (
            Object.values(orders)?.map((bounty: Bounty, index) => (
              <Box mb={5} key={bounty?.id || index}>
                <OrderInformation bounty={bounty} />
              </Box>
            ))
          ) : (
            <EmptyState message="No orders yet" />
          )}
        </Container>
      )}
    </>
  );
};

export default OrdersPage;
