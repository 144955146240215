import { Box, Divider, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import { OrderStatus } from 'constants/enums';

export const StyledBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
  borderRadius: '4px',
  padding: theme.spacing(2),
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.common.black, 0.1),
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 1),
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
  opacity: 0.9,
}));

export const StatusTypography = styled(Typography)<{ status?: OrderStatus }>(({ theme, status }) => ({
  color: status && [OrderStatus.DECLINED, OrderStatus.CANCELLED].includes(status)
    ? theme.palette.general.darkYellow1
    : theme.palette.primary.main,
}));

export const StyledInfoWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(0.5),
  [theme.breakpoints.down('sm')]: {
    display: 'inline-block',
    marginTop: theme.spacing(2),
  },
}));
