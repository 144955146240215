import { Grid } from '@mui/material';
import {
  add, format, isAfter, isToday,
} from 'date-fns';
import { get } from 'lodash';
import { FC, useCallback, useContext } from 'react';

import CustomDatePicker from 'components/CustomDatePicker';
import CustomTimePicker from 'components/CustomTimePicker';
import { SystemContext } from 'context/SystemContext';

import { DeliveryMethod, FieldRuleType } from 'constants/enums';
import { PICK_UP_DATE, PICK_UP_TIME } from 'constants/fields';
import { DEFAULT_TIME_FORMAT, PICKUP_MINUTES_STEP } from 'constants/general';
import { OrderDateRestrictions } from 'types/checkout.interface';
import { getDayLimitBasedOnDelivery, hasOrderDateRestrictions, isPickupMethod } from 'utils/checkoutUtils';
import { isValidHourForPlacingOrder, isShopCloseByTime, isShopClosed } from 'utils/shopOperation';

interface PickupInformationFormProps {
  onValueChange: (value: any, name: string) => void;
  delivery: {
    method: DeliveryMethod;
    pickupDate?: Date|null;
    pickupTime?: Date|null;
  };
  fieldErrors: Record<string, string>;
  orderDateRestrictions: OrderDateRestrictions | null;
  fieldsRules?: Record<string, FieldRuleType>;
  isLoadingDateRestrictions?: boolean;
}

const PickupInformationForm :FC<PickupInformationFormProps> = ({
  onValueChange,
  fieldErrors,
  delivery,
  orderDateRestrictions,
  fieldsRules,
  isLoadingDateRestrictions = false,
}) => {
  const timePickerRestrictions = delivery?.pickupDate
    ? isShopCloseByTime(delivery?.pickupDate, delivery.method)
    : { disabled: true };
  const { shopSettings } = useContext(SystemContext);
  const hoursOfOperation = shopSettings.effectiveShopOperation?.hoursOfOperation;
  const dayCap = hoursOfOperation
    ? getDayLimitBasedOnDelivery(hoursOfOperation, delivery.method)
    : 0;
  const minPickupTime = (delivery?.pickupDate && isToday(delivery?.pickupDate)) ? new Date() : undefined;
  const isPickup = isPickupMethod(delivery?.method);

  const checkIfClosed = (date: Date) => {
    const maxPickupDate = add(new Date(), { days: dayCap });

    if (!!dayCap && isAfter(date, maxPickupDate) && hasOrderDateRestrictions(delivery.method)) {
      return true;
    }

    return isShopClosed(date, delivery.method);
  };

  const handleTimePicker = (date: Date, name: string) => {
    if (date) {
      const time = format(new Date(date || ''), DEFAULT_TIME_FORMAT);
      const [hour, minute] = time.split(':');
      const pickupTime = delivery?.pickupDate
        ? new Date(new Date(delivery?.pickupDate).setHours(+hour, +minute, 0, 0))
        : date;

      onValueChange(pickupTime, name);
    } else {
      onValueChange(date, name);
    }
  };

  const handleDatePicker = (date: Date, name: string) => {
    onValueChange(date, name);

    if (fieldsRules?.pickupTime !== FieldRuleType.Required) {
      onValueChange(null, PICK_UP_TIME.name);
      return;
    }

    if (delivery?.pickupTime) {
      const time = format(new Date(delivery?.pickupTime || ''), DEFAULT_TIME_FORMAT);
      const [hour, minute] = time.split(':');
      const pickupTime = new Date(new Date(date).setHours(+hour, +minute, 0, 0));

      onValueChange(pickupTime, PICK_UP_TIME.name);
    }
  };

  const shouldDisableTime = useCallback((clockValue: Date | null) => (
    isPickup && !isValidHourForPlacingOrder(clockValue, orderDateRestrictions)
  ), [orderDateRestrictions, isPickup]);

  return (
    <>
      <Grid item xs={12} sm={6}>
        <CustomDatePicker
          {...PICK_UP_DATE}
          label={isPickup ? PICK_UP_DATE.label : 'Delivery Date'}
          onChange={handleDatePicker}
          shouldDisableDate={checkIfClosed}
          size="small"
          value={delivery?.pickupDate}
          error={!!get(fieldErrors, PICK_UP_DATE.name)}
          helperText={get(fieldErrors, PICK_UP_DATE.name)}
        />
      </Grid>

      {(fieldsRules?.pickupTime !== FieldRuleType.Hidden && isPickup) && (
        <Grid item xs={12} sm={6}>
          <CustomTimePicker
            {...PICK_UP_TIME}
            label={PICK_UP_TIME.label}
            required={fieldsRules?.pickupTime === FieldRuleType.Required}
            onChange={handleTimePicker}
            disabled={!delivery?.pickupDate || isLoadingDateRestrictions}
            size="small"
            value={delivery?.pickupTime}
            error={!!get(fieldErrors, PICK_UP_TIME.name)}
            helperText={get(fieldErrors, PICK_UP_TIME.name)}
            minTime={minPickupTime}
            shouldDisableTime={shouldDisableTime}
            timeSteps={{ minutes: PICKUP_MINUTES_STEP }}
            {...timePickerRestrictions}
          />
        </Grid>
      )}
    </>
  );
};

export default PickupInformationForm;
