import { Box, ButtonBase } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledCollapse = styled(Box)(({ theme }) => ({
  width: '370px',
  background: theme.palette.common.white,
  borderRadius: '6px',
  overflow: 'hidden',
  boxShadow: '0 4px 16px rgba(103, 113, 121, 0.15)',
  [theme.breakpoints.down('md')]: {
    right: 24,
  },
  [theme.breakpoints.down('sm')]: {
    width: '275px',
    right: 0,
  },
  border: `1px solid ${theme.palette.border.main}`,
}));

export const StyledButton = styled(ButtonBase)(({ theme }) => ({
  color: theme.palette.general.lightGrey2,
  height: '100%',
}));

export const StyledShoppingCart = styled('div')(({ theme }) => ({
  padding: theme.spacing(0.5),
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: '20px',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  gap: theme.spacing(2),
  '&:hover': {
    backgroundColor: theme.palette.background.secondary,
  },
  backgroundColor: theme.palette.common.white,
}));
